import { Injectable } from '@angular/core'
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http'
import { BehaviorSubject } from 'rxjs'
import { catchError, concatMap, map, tap } from 'rxjs/operators'
import { Constants } from '../global-data/constants'
import { SessionService } from './session.service'
import * as moment from 'moment'
import { Router } from '@angular/router'
import { captureCompaignParameters } from '../global-data/commonData'
import { commonFunctions } from '../global-data/commonFunction'

// import { constants } from 'buffer';
// import { host } from './shared/server';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private loadingStatus = new BehaviorSubject<boolean>(false)
  getLoadingStatus = this.loadingStatus.asObservable()
  constructor(
    private http: HttpClient,
    private session: SessionService, // private constants: Constants
    private router: Router,
  ) {}
  get(url, params, token = null) {
    let param = JSON.parse(JSON.stringify(params))
    var setparams = new HttpParams({
      fromObject: param,
    })
    const options = {
      params: setparams,
      headers: {},
    }
    if(params && params.companyId === null){
      return;
    }
    if(url && url.indexOf('companies/null')>=0){
      return;
    }
    
    if(token){
      options.headers = { Authorization: 'Bearer ' + token }
    }
    else if(localStorage.getItem('auth.token') && JSON.parse(localStorage.getItem('auth.token')).val){
      options.headers = {
        Authorization: localStorage.getItem('auth.token')
          ? 'Bearer ' + JSON.parse(localStorage.getItem('auth.token')).val
          : null,
      }
      if(url == 'users/create/otp' && window.location.pathname == '/login'){
        options.headers = { }
      }
    }
   
    this.loadingStatus.next(true)
    return this.http.get(Constants.apiPath + url, options).pipe(
      tap(
        (response) => {
          this.loadingStatus.next(false)
          return response
        },
        (err) => {
          if(err && err.status && err.status == 403 && err.error && err.error.message == 'This account has been deactivated. Kindly contact account admin.'){
            this.companyIsDeactivated()
          }
          this.loadingStatus.next(false)
          return err
        }
      )
    )
  }
  getWithToken(url, params) {
    let param = JSON.parse(JSON.stringify(params))
    var setparams = new HttpParams({
      fromObject: param,
    })
    const options = {
      params: setparams,
      headers: {},
    }
    if(params && params.companyId === null){
      return;
    }
    if(url && url.indexOf('companies/null')>=0){
      return;
    }
    if (localStorage.getItem('auth.token')) {
      options.headers = {
        Authorization: JSON.parse(localStorage.getItem('auth.token')).val
          ? 'Bearer ' + JSON.parse(localStorage.getItem('auth.token')).val
          : null,
      }
    }
    this.loadingStatus.next(true)
    return this.http.get(Constants.apiPath + url, options).pipe(
      tap(
        (response) => {
          this.loadingStatus.next(false)
          return response
        },
        (err) => {
          if(err && err.status && err.status == 403 && err.error && err.error.message == 'This account has been deactivated. Kindly contact account admin.'){
            this.companyIsDeactivated()
          }
          this.loadingStatus.next(false)
          return err
        }
      )
    )
  }
  getVersion(url, params) {
    let param = JSON.parse(JSON.stringify(params))
    var setparams = new HttpParams({
      fromObject: param,
    })
    const options = {
      params: setparams,
      headers: {},
    }
    options.headers = {
      "Cache-Control" : 'no-cache',
      "Pragma" : "no-cache"
    }
    if (localStorage.getItem('auth.token') && JSON.parse(localStorage.getItem('auth.token')).val) {
      options.headers = {
        Authorization: localStorage.getItem('auth.token')
          ? 'Bearer ' + JSON.parse(localStorage.getItem('auth.token')).val
          : null,
      }
      
    }
    this.loadingStatus.next(true)
    return this.http.get(Constants.apiPath + url, options).pipe(
      tap(
        (response) => {
          this.loadingStatus.next(false)
          return response
        },
        (err) => {
          this.loadingStatus.next(false)
          return err
        }
      )
    )
  }
  getLive(url, params) {
    let param = JSON.parse(JSON.stringify(params))
    var setparams = new HttpParams({
      fromObject: param,
    })
    const options = {
      params: setparams,
      headers: {},
    }
    if (localStorage.getItem('auth.token') && JSON.parse(localStorage.getItem('auth.token')).val) {
      options.headers = {
        Authorization: localStorage.getItem('auth.token')
          ? 'Bearer ' + JSON.parse(localStorage.getItem('auth.token')).val
          : null,
      }
    }
    this.loadingStatus.next(true)
    return this.http.get(Constants.apiPathLive + url, options).pipe(
      tap(
        (response) => {
          this.loadingStatus.next(false)
          return response
        },
        (err) => {
          this.loadingStatus.next(false)
          return err
        }
      )
    )
  }
  put(url, params) {
    let param = JSON.parse(JSON.stringify(params))
    var setparams = new HttpParams({
      fromObject: param,
    })
    const options = {
      // params: setparams,
      headers: {},
    }
    var body = params
    if (localStorage.getItem('auth.token')) {
      options.headers = {
        Authorization: localStorage.getItem('auth.token')
          ? 'Bearer ' + JSON.parse(localStorage.getItem('auth.token')).val
          : 'Bearer ' + this.session.getUser().email,
      }
    }
    else{
      options.headers = {
        Authorization: 'Bearer ' + this.session.getUser().email
      }
    }
    if(this.session.getUser()?.companyId && !body['companyId']){
      body['companyId']=this.session.getUser()?.companyId
    }
    
    this.loadingStatus.next(true)
    return this.http.put(Constants.apiPath + url, body, options).pipe(
      tap(
        (response) => {
          this.loadingStatus.next(false)
          return response
        },
        (err) => {
          if(err && err.status && err.status == 403 && err.error && err.error.message == 'This account has been deactivated. Kindly contact account admin.'){
            this.companyIsDeactivated()
          }
          this.loadingStatus.next(false)
          return err
        }
      )
    )
  }
  putWithToken(url, params) {
    let param = JSON.parse(JSON.stringify(params))
    var setparams = new HttpParams({
      fromObject: param,
    })
    const options = {
      // params: setparams,
      headers: {},
    }
    var body = params
    if (localStorage.getItem('auth.token') && JSON.parse(localStorage.getItem('auth.token')).val) {
      options.headers = {
        Authorization: JSON.parse(localStorage.getItem('auth.token')).val
          ? 'Bearer ' +  JSON.parse(localStorage.getItem('auth.token')).val
          : null,
      }
      console.log('Authorization :', options.headers)
    }
    if(this.session.getUser()?.companyId && !body['companyId']){
      body['companyId']=this.session.getUser()?.companyId
    }
    this.loadingStatus.next(true)
    return this.http.put(Constants.apiPath + url, body, options).pipe(
      tap(
        (response) => {
          this.loadingStatus.next(false)
          return response
        },
        (err) => {
          if(err && err.status && err.status == 403 && err.error && err.error.message == 'This account has been deactivated. Kindly contact account admin.'){
            this.companyIsDeactivated()
          }
          this.loadingStatus.next(false)
          return err
        }
      )
    )
  }
  postWithEmail(url, body , email) {
    // let headers = new HttpHeaders();
    // console.log('details', body);
    var headers = {}
    if (this.getUserEmail()) {
      headers = {
        Authorization: email
          ? 'Bearer ' + email
          : 'Bearer ' + this.getUserEmail(),
      }
    }
    if(this.session.getUser()?.companyId && !body['companyId']){
      body['companyId']=this.session.getUser()?.companyId
    }
    this.loadingStatus.next(true)
    return this.http.post(Constants.apiPath + url, body, { headers: headers }).pipe(
      tap(
        (response) => {
          this.loadingStatus.next(false)
          return response
        },
        (err) => {
          if(err && err.status && err.status == 403 && err.error && err.error.message == 'This account has been deactivated. Kindly contact account admin.'){
            this.companyIsDeactivated()
          }
          this.loadingStatus.next(false)
          return err
        }
      )
    )
    // return this.http.post(host + url, body)
  }
  putWithBody(url, body) {
    var headers = {}
    if (localStorage.getItem('auth.token') && JSON.parse(localStorage.getItem('auth.token')).val) {
      headers = {
        Authorization: localStorage.getItem('auth.token')
          ? 'Bearer ' + JSON.parse(localStorage.getItem('auth.token')).val
          : null,
      }
    }
    if(this.session.getUser()?.companyId && !body['companyId']){
      body['companyId']=this.session.getUser()?.companyId
    }
    this.loadingStatus.next(true)
    return this.http.put(Constants.apiPath + url, body, { headers: headers }).pipe(
      tap(
        (response) => {
          this.loadingStatus.next(false)
          return response
        },
        (err) => {
          if(err && err.status && err.status == 403 && err.error && err.error.message == 'This account has been deactivated. Kindly contact account admin.'){
            this.companyIsDeactivated()
          }
          this.loadingStatus.next(false)
          return err
        }
      )
    )
  }
  putWithBodyAndToken(url, body) {
    var headers = {}
    if (localStorage.getItem('auth.token') && JSON.parse(localStorage.getItem('auth.token')).val) {
      headers = {
        Authorization: JSON.parse(localStorage.getItem('auth.token')).val
          ? 'Bearer ' + JSON.parse(localStorage.getItem('auth.token')).val
          : null,
      }
    }
    if(this.session.getUser()?.companyId && !body['companyId']){
      body['companyId']=this.session.getUser()?.companyId
    }
    this.loadingStatus.next(true)
    return this.http.put(Constants.apiPath + url, body, { headers: headers }).pipe(
      tap(
        (response) => {
          this.loadingStatus.next(false)
          return response
        },
        (err) => {
          if(err && err.status && err.status == 403 && err.error && err.error.message == 'This account has been deactivated. Kindly contact account admin.'){
            this.companyIsDeactivated()
          }
          this.loadingStatus.next(false)
          return err
        }
      )
    )
  }
  post(url, body) {
    // let headers = new HttpHeaders();
    // console.log('details', body);
    var headers = {}
    if (localStorage.getItem('auth.token') && JSON.parse(localStorage.getItem('auth.token')).val) {
      headers = {
        Authorization: localStorage.getItem('auth.token')
          ? 'Bearer ' + JSON.parse(localStorage.getItem('auth.token')).val
          : null,
      }
    }
    if(this.session.getUser() && this.session.getUser().companyId &&  (this.session.getUser().companyId == '6509d48d0f0998cab60b576b') && (url == 'visits' || url ==  'securityTeams/verify/visit' || url ==  'visits/visitor/checkout' || url == 'visits/preVisit/generatePass')){
        this.setAPIAndSubscriptionKey(headers)
    }
    if(this.session.getUser()?.companyId && !body['companyId']){
      body['companyId']=this.session.getUser()?.companyId
    }
    this.loadingStatus.next(true)
    return this.http.post(Constants.apiPath + url, body, { headers: headers }).pipe(
      tap(
        (response) => {
          this.loadingStatus.next(false)
          return response
        },
        (err) => {
          if(err && err.status && err.status == 403 && err.error && err.error.message == 'This account has been deactivated. Kindly contact account admin.'){
            this.companyIsDeactivated()
          }
          this.loadingStatus.next(false)
          return err
        }
      )
    )
    // return this.http.post(host + url, body)
  }
  postLive(url, body) {
    // let headers = new HttpHeaders();
    // console.log('details', body);
    var headers = {}
    if (this.getUserEmail()) {
      headers = {
        Authorization: localStorage.getItem('auth.email')
          ? 'Bearer ' + JSON.parse(localStorage.getItem('auth.email')).val
          : null,
      }
    }
    if(url == '/lead'){
        this.checkLeadCompaignParameters(body)
    }
    if(this.session.getUser()?.companyId && !body['companyId']){
      body['companyId']=this.session.getUser()?.companyId
    }
    this.loadingStatus.next(true)
    return this.http.post(Constants.apiPathLive + url, body, { headers: headers }).pipe(
      tap(
        (response) => {
          this.loadingStatus.next(false)
          return response
        },
        (err) => {
          this.loadingStatus.next(false)
          return err
        }
      )
    )
    // return this.http.post(host + url, body)
  }
  postWithToken(url, body) {
    // let headers = new HttpHeaders();
    // console.log('details', body);
    var headers = {}
    if (localStorage.getItem('auth.token') && JSON.parse(localStorage.getItem('auth.token')).val) {
      headers = {
        Authorization: JSON.parse(localStorage.getItem('auth.token')).val
          ? 'Bearer ' + JSON.parse(localStorage.getItem('auth.token')).val
          : null,
      }
    }
    if(this.session.getUser()?.companyId && !body['companyId']){
      body['companyId']=this.session.getUser()?.companyId
    }
    this.loadingStatus.next(true)
    return this.http.post(Constants.apiPath + url, body, { headers: headers }).pipe(
      tap(
        (response) => {
          this.loadingStatus.next(false)
          return response
        },
        (err) => {
          if(err && err.status && err.status == 403 && err.error && err.error.message == 'This account has been deactivated. Kindly contact account admin.'){
            this.companyIsDeactivated()
          }
          this.loadingStatus.next(false)
          return err
        }
      )
    )
    // return this.http.post(host + url, body)
  }
  postWithTokenSecurity(url, body,token) {
    // let headers = new HttpHeaders();
    // console.log('details', body);
    var headers = {}
    if (localStorage.getItem('auth.token')) {
      headers = {
        Authorization: token
          ? 'Bearer ' + token
          : null,
      }
    }
    if(this.session.getUser()?.companyId && !body['companyId']){
      body['companyId']=this.session.getUser()?.companyId
    }
    this.loadingStatus.next(true)
    return this.http.post(Constants.apiPath + url, body, { headers: headers }).pipe(
      tap(
        (response) => {
          this.loadingStatus.next(false)
          return response
        },
        (err) => {
          if(err && err.status && err.status == 403 && err.error && err.error.message == 'This account has been deactivated. Kindly contact account admin.'){
            this.companyIsDeactivated()
          }
          this.loadingStatus.next(false)
          return err
        }
      )
    )
    // return this.http.post(host + url, body)
  }
  getGeoCode(address) {
    var headers = {}
    if (this.getUserEmail()) {
      headers = {
        Authorization: localStorage.getItem('auth.email')
          ? 'Bearer ' + JSON.parse(localStorage.getItem('auth.email')).val
          : null,
      }
    }
    this.loadingStatus.next(true)
    return this.http.post('https://api.qdesq.com/google/search', address, { headers: headers }).pipe(
      tap(
        (response) => {
          this.loadingStatus.next(false)
          return response
        },
        (err) => {
          this.loadingStatus.next(false)
          return err
        }
      )
    )
  }
  getSignatureKeys(file, cb) {
    var keys = this.session.getImagekitKeys()
    console.log(keys)
    var now = moment(new Date()) //todays date
    // var end = moment("2015-12-1"); // another date
    var duration = moment.duration(now.diff(keys.savedOn))
    var hours = duration.asHours()
    console.log(hours)
    if (hours > 1 && keys) {
      let getUrl = Constants.apiPath + '/imagekit/generateToken'
      console.log(getUrl)
      this.get('/imagekit/generateToken', {}).pipe(
        tap((res) => {
          console.log(res)
          console.log('generate token', res)
          keys = res
          keys['savedOn'] = new Date()
          this.session.setImagekitKeys(keys)
          var formData = new FormData()
          formData.append('file', file)
          formData.append('fileName', file.name)
          formData.append('tags', 'image')
          formData.append('publicKey', 'TpLRdW6j8vBbXus7Jei4B3vl9Sc=')
          // cb(keys);
        })
        // concatMap((res) =>
        //   this.http.post('https://upload.imagekit.io/api/v1/files/upload', formData)
        // )
      )
      // this.http.get('http://test.localhost/api.php?timeout=1')
      // .pipe(
      //   tap(res => console.log('First result', res)),
      //   concatMap((res: { timeout: number }) => this.http.get(`http://test.localhost/api.php?timeout=${+res.timeout + 1}`)),
      //   tap(res => console.log('Second result', res)),
      //   concatMap((res: { timeout: number }) => this.http.get(`http://test.localhost/api.php?timeout=${+res.timeout + 3}`)),
      //   tap(res => console.log('Third result', res)),
      // )
    } else {
      cb(keys)
    }
  }
  imagekitUpload(file, callBack) {
    console.log('inside api')
    let formData = new FormData()
    formData.append('file', file)
    formData.append('fileName', file.name)
    formData.append('tags', 'image')
    formData.append('publicKey', 'TpLRdW6j8vBbXus7Jei4B3vl9Sc=')
    this.getSignatureKeys(file, (cb) => {
      console.log(cb)
      console.log('inside api 2')
      let keys = cb.authenticationParameters
      let uploadUrl = 'https://upload.imagekit.io/api/v1/files/upload'
      // let options = {}
      var as = this.sendFile(file, keys)
      console.log(as)
      formData.append('signature', keys.signature)
      formData.append('token', keys.token)
      formData.append('expire', keys.expire)
      console.log(formData.getAll('expire'))
      // this.sendFile(file, keys)
      this.http.post(uploadUrl, formData).pipe(
        tap((response) => {
          this.loadingStatus.next(false)
          callBack(response)
        })
      )
    })
  }

  sendFile(file, keys) {
    let formData = new FormData()
    formData.append('file', file)
    formData.append('fileName', file.name)
    formData.append('tags', 'image')
    formData.append('publicKey', 'TpLRdW6j8vBbXus7Jei4B3vl9Sc=')
    let uploadUrl = 'https://upload.imagekit.io/api/v1/files/upload'

    formData.append('signature', keys.signature)
    formData.append('token', keys.token)
    formData.append('expire', keys.expire)
    console.log(formData.get('file'), formData.get('publicKey'))
    return this.http.post(uploadUrl, formData).pipe(
      tap((response) => {
        this.loadingStatus.next(false)
        console.log(response)
        return response
        // cb(response)
        // callBack(response);
      })
    )
  }
  uploadImage(body) {
    console.log('body', body)
    const uploadData = new FormData()
    uploadData.append('file', body.file)
    uploadData.append('upload_preset', body.upload_preset)
    if(body.type){
      uploadData.append('type', body.type)
    }
    console.log('uploadData', uploadData)
    this.loadingStatus.next(true)
    return this.http.post(Constants.apiPath+'upload/images', uploadData).pipe(
      tap((response) => {
        this.loadingStatus.next(false)
        return response
      })
    )
  }
  uploadDocument(body) {
    console.log('body', body)
    const uploadData = new FormData()
    uploadData.append('file', body.file)
    uploadData.append('upload_preset', body.upload_preset)
    console.log('uploadData', uploadData)
    this.loadingStatus.next(true)
    return this.http.post(Constants.apiPath+'upload/images', uploadData).pipe(
      tap((response) => {
        this.loadingStatus.next(false)
        return response
      })
    )
  }
  downloadDocument(url, params) {
    let param = JSON.parse(JSON.stringify(params))
    var setparams = new HttpParams({
      fromObject: param,
    })
    const options = {
      params: setparams,
      headers: {},
      observe: null,
      responseType: null,
    }
    options.observe = 'response'
    options.responseType = 'blob'
    // options.headers = {
    //   "Cache-Control" : 'no-cache',
    //   "Pragma" : "no-cache",
    //   'Access-Control-Allow-Headers' : '*',
    //   'Access-Control-Allow-Origin' : '*'
    // }
    
    if(localStorage.getItem('auth.token')) {
      options.headers = {
        Authorization: JSON.parse(localStorage.getItem('auth.token')).val
          ? 'Bearer ' + JSON.parse(localStorage.getItem('auth.token')).val
          : null,
      }
      
    }
    this.loadingStatus.next(true)
    return this.http.get(Constants.apiPath + url, options).pipe(
      tap(
        (response) => {
          this.loadingStatus.next(false)
          return response
        },
        (err) => {
          this.loadingStatus.next(false)
          return err
        }
      )
    )
  }
  uploadImageWithProgress(body) {
    console.log('body', body)
    const uploadData = new FormData()
    uploadData.append('file', body.file)
    uploadData.append('upload_preset', body.upload_preset)
    console.log('uploadData', uploadData)
    this.loadingStatus.next(true)
    return this.http.post(Constants.apiPath+'upload/images', uploadData,{ reportProgress: true,
    observe: 'events'}).pipe(
      tap((response) => {
        this.loadingStatus.next(false)
        return response
      })
    )
  }
  getUserEmail() {
    var str = null
    if (localStorage.getItem('auth.email')) str = JSON.parse(localStorage.getItem('auth.email')).val
    return str
  }
  companyIsDeactivated(){
    if(window.location.href.indexOf('account-deactivated')<0){
      this.router.navigate(['account-deactivated/'+this.session.getUser().companyId])
    }
    
  }
  setAPIAndSubscriptionKey(header){
    if(header){
      header['apiKey'] = 'U2FsdGVkX1/O33Hi5EoWJtZx7ZYpanz+OtWajBkcs64s+HmRvCqS9k1Hs0M+QI7I'
      header['subscriptionKey'] = '636038337b0be2e25b31ca1339c96c3aaa990434dcb05d1575a979c08e25d146'
    }
  }
  getCompaignParameterCookie(name) {
    var value = `; ${document.cookie}`;
    var parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }
  checkLeadCompaignParameters(body){

    if(captureCompaignParameters?.length){

      for(let item of captureCompaignParameters){

        if(this.getCompaignParameterCookie(item.urlKey) && this.getCompaignParameterCookie(item.urlKey) !=''){

          if(!body[item.leadkey] || body[item.leadkey]==''){

            body[item.leadkey] = this.getCompaignParameterCookie(item.urlKey)      // localStorage.getItem(item.urlKey);

          }

        }

      }


    }

  }
}
