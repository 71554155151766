import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { ActivatedRoute, Router } from '@angular/router'
import { AuthenticationClass } from './auth'
import jwt_decode from 'jwt-decode'
import * as moment from 'moment'
import { CompanyDetailsService } from '../@services/company-details.service'
import { Constants } from '../global-data/constants'
import { HttpClient } from '@angular/common/http'
import { ignoreLoginNavigationUrls, constSessionCredentials } from '../global-data/commonData'
import * as CryptoJS from 'crypto-js';
@Injectable({
  providedIn: 'root',
})
export class SessionService {
  userData: any = {}
  private CurrentAuthSource = new BehaviorSubject<object>({
    isLoggedIn: localStorage.getItem('auth.token') && JSON.parse(localStorage.getItem('auth.token')).val ? this.getCompanyDetails(() => { this.CurrentAuthSource.next({isLoggedIn: true}) }) : false,
  })
  
  getCurrentLoginStatus = this.CurrentAuthSource.asObservable()
  constructor(
    private route: ActivatedRoute, 
    private router: Router,
    private storeCompanyDetails : CompanyDetailsService,
    private http: HttpClient,
    ) {}

  setImagekitKeys(imagekitKey: any) {
    localStorage.setItem('imagekitKey', JSON.stringify(imagekitKey))
    var result = { message: 'keys saved', imagekitKey: imagekitKey }
    return result
  }
  setVisitorId(visitorId: any) {
    localStorage.setItem('visitorId', JSON.stringify(visitorId))
    var result = {visitorId: visitorId}
    return result
  }
  getVisitorId() {
    let keys = JSON.parse(localStorage.getItem('visitorId'))
    return keys
  }
  getImagekitKeys() {
    let keys = JSON.parse(localStorage.getItem('imagekitKey'))
    return keys
  }
  clearAll() {
    localStorage.clear()
  }
  async SaveLoggedInUser(LoggedInUser, sessionName = 'auth.token') {
    var result: any = { success: true, message: 'you are now logged in' }

    // LoggedInUser.token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1ZWQiOiIxMzgwZjllZWIzOTUyYjNiOTA4MDI2NDQzYTA4MWZlYzdjMWJjNjJhZWU4MzY0OWQ0OThjYTQ5MjZkNmJlYzVmNTM1NmVlYWNjYmEzYzFlZjBiZGU4MTRjM2UyZDVkNTk3MWUxN2Q2ODU2ODllZjQ0Njg4MTgyMDU3OWIwMTM2YjhlNDAzNzNmY2E4NDY2YzhiMDUwNDdkOGMyMDRiNDlhOGRhMzkzMGRlZmNiZjY1NzUyOTBmODc4Y2JkMjhhZGQ4M2FhMmUzYmUxZDA5NmQ5MzJjMWZhNTQ5YzExNzhiNjNjOGI5ZjM0NzM0YjYxMmYxNmFlY2JhODU1NDU3NmRhODY4NDVlYzk0NWI0NDIxMTRkZGJjNDU0NTQ4NjJjZDY5Y2I5ZWQ3OGU0YjhhMTRhNWI3YjQ0NDBmMDRiOThiNzU3NmYwMzkwYjA2MzI4YmYwOTc4YmVmOTU0ZGRhZWY1ZmEyMjI5MGI5YjFlMDVkZDVjNjM4ZDYzMjAwN2QzODdmZTRkYTRiNGUyZjdiMTk1YzJhMzFkNzljYWM1NGFkMDliOGRjYjdlMDExNjA5ODEyNjk4OTE3NjQxNTYzZjYwMmJjNDYxYzc1YjExYWQ4OTUzYTNlMzZhZWIxY2Y5YTY4YmMzOGFkNzZiN2JhZjMwOTZiY2IyNzI0MGQ3N2I4ZjQ5MDlhNTJhNWNiMTg5NTA2Y2ExOTkwZDMzM2MwZjljOTBkMGQwYjkwOWQzNTkyNmM5NzA5MWQ0MTQ2NjI0MTgyZTViY2VkZjE2YzVlZGExODM5ZGY2YjkyMDI5MjcwYjczODk5ZmU0YzY0OGEyZDJiNjVhNTNiZjY2MTNmNjBhYmNjYTQ0YjRkODFkNDUwYWI3ZjA1MGEzMTg4NmEyMTYxMzlkNDI0MTdiNTllNmRhMGU1ZmM2MDAzNTk1OThlNCIsImV4cGlyZXNPbiI6IjIwMjQtMTItMjBUMTA6MjM6MzcuOTM2WiIsImlhdCI6MTczMjA5ODIxN30.KCy8I6VujJGuhwzNPqUH2vL5rOD_nETUDpdZF6hJfL0"

    localStorage.setItem(sessionName, this.auth(LoggedInUser.token))
    this.getCompanyDetails(() => {
      AuthenticationClass.IsValidLogin = true
      this.CurrentAuthSource.next({ isLoggedIn: AuthenticationClass.IsValidLogin })
      return result
    })
    
  }
  async Logout(ignoreLoginNavigation=false) {
    this.storeCompanyDetails.companyDetails = null
    this.storeCompanyDetails.featuresEnabled = []
    localStorage.clear()
    AuthenticationClass.IsValidLogin = false
    // this.authService.signOut()
    // window.location.reload()
    if(!ignoreLoginNavigation){
      this.CurrentAuthSource.next({ isLoggedIn: AuthenticationClass.IsValidLogin })
    }
    
    
    return true
  }
  auth(str) {
    let obj = { val: str, lifetime: -1, timestamp: new Date().getTime() }
    return JSON.stringify(obj)
  }
  getUser(ignoreLoginNavigation=false,sessionName = 'auth.token') {
    ignoreLoginNavigation = ignoreLoginNavigation || this.isIgnoreUrls() 
    if (localStorage.getItem(sessionName) && JSON.parse(localStorage.getItem(sessionName)).val) {
      this.userData = this.decryptTokenData(localStorage.getItem(sessionName))
    } else {
      this.userData = {}
    }
    console.log('user data ==== ', this.userData)
    // return this.userData;
    if (this.userData && this.userData.expiresOn) {
      if (this.userData.expiresOn < moment().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')) {
        this.Logout(ignoreLoginNavigation)
        if(!ignoreLoginNavigation){
          this.router.navigate(['/login'])
        }
      }
    }
    this.userData.userId = this.userData.userId ? this.userData.userId : this.userData.userid
    return {
      user_img: this.userData.picture ? this.userData.picture : null,
      name: this.userData.name ? this.userData.name : null,
      userId: this.userData.userId ? this.userData.userId : null,
      email: this.userData.email ? this.userData.email.trim().toLowerCase() : null,
      number: this.userData.contactNumber ? this.userData.contactNumber : null,
      isAdmin: this.userData.isAdmin ? this.userData.isAdmin : null,
      companyId : this.userData.companyId ? this.userData.companyId : null,
      officeId : this.userData.officeId ? this.userData.officeId : null,
      domainName: this.userData.domainName,
      roles: this.userData.role ? this.userData.role : [],
      countryCode:this.userData.countryCode,
      visitorId: this.userData.visitorId || null,
      userType: this.userData.userType
    }
  }
  getCompanyDetails(callBack){
    const options = {
      params: null,
      headers: {},
    }
    if(localStorage.getItem('auth.token') && JSON.parse(localStorage.getItem('auth.token')).val){
      options.headers = {
        Authorization: localStorage.getItem('auth.token')
          ? 'Bearer ' + JSON.parse(localStorage.getItem('auth.token')).val
          : null,
      }
    }
    if(!this.getUser().companyId){
      callBack();
      return;
    };
    this.http.get(Constants.apiPath + 'companies/'+this.getUser().companyId, options).subscribe(
        (response:any) => {
          this.storeCompanyDetails.companyDetails = response
          callBack()
          return response
        },
        (err) => {
          return err
        }
    )
  }
  setVisitorCheckinRefId(refId){
    localStorage.setItem('visitor.checkin.admin.login.RefID',refId)
  }
  getVisitorCheckinRefId() {
    if(localStorage.getItem('visitor.checkin.admin.login.RefID') && localStorage.getItem('visitor.checkin.admin.login.RefID').length>0){
      return localStorage.getItem('visitor.checkin.admin.login.RefID')
    }
    return null;
  }
  clearVisitorCheckinRefId(){
    localStorage.removeItem('visitor.checkin.admin.login.RefID')
  }
  setMeetingRoomLoginRefId(refId){
    localStorage.setItem('meeting-room.login.RefID',refId)
  }
  getMeetingRoomLoginRefId() {
    if(localStorage.getItem('meeting-room.login.RefID') && localStorage.getItem('meeting-room.login.RefID').length>0){
      return localStorage.getItem('meeting-room.login.RefID')
    }
    return null;
  }
  clearMeetingRoomLoginRefId(){
    localStorage.removeItem('meeting-room.login.RefID')
  }
  setSSOLoginRedirectURI(url){
    localStorage.setItem('sso.login.redirect.uri',url);
  }
  getSSOLoginRedirectURI(){
    return localStorage.getItem('sso.login.redirect.uri');
  }
  removeSSOLoginRedirectURI(){
    localStorage.removeItem('sso.login.redirect.uri');
  }
  setPwaDetails(data, key){
    localStorage.setItem(key,JSON.stringify(data))
  }
  getPwaDetails(key){
    if(localStorage.getItem(key) && JSON.parse(JSON.stringify(localStorage.getItem(key)))){
      return JSON.parse(localStorage.getItem(key))
    }
    return null;
  }

  isIgnoreUrls(){
    for(let url of ignoreLoginNavigationUrls){
      if(window.location.href.indexOf(url)>=0){
        return true
      }
    }
    return false;
  }
  decryptTokenData(token){

    let encryptedData = jwt_decode(token)['ued']

    if(!encryptedData){
      return jwt_decode(token)
    }
      const algorithm = 'aes-256-cbc'; // This is for reference. CryptoJS uses AES directly.
      const iv = CryptoJS.enc.Hex.parse(constSessionCredentials.secretv.replace('98','97'));
      const key = CryptoJS.enc.Utf8.parse(constSessionCredentials.secretKey.replace('VF','VH')); // Convert key to Utf8

    try {
      // Decode encrypted data (from Hex)
      const encryptedHexStr = CryptoJS.enc.Hex.parse(encryptedData);
      const encryptedBase64Str = CryptoJS.enc.Base64.stringify(encryptedHexStr);

      // Decrypt
      const decrypted = CryptoJS.AES.decrypt(encryptedBase64Str, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });

      // Convert to UTF-8
      return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
    } catch (error) {
      console.error('Decryption failed:', error);
      return '';
    }
  }
}
