import { Injectable, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '../common-modules/confirmation-modal/confirmation-modal/confirmation-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationModalServiceService {

  constructor(private dialog: MatDialog) { }
  
  errorMsg:any;
  successMsg:any;

  // @Input() title
  // @Input() message:any
  // action = add, update, delete, error
  open(action,title, message, description= null, image = null, additionalSettings=null) {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: '400px',
      disableClose: additionalSettings?.restrictAutoClose,
      data: {action, title, message, description,image, additionalSettings},
    });
    return dialogRef;
  }


  // open(action,add,update,remove){

  // }
}
