export const roles = ['Super Admin','Admin']

// let companyName = 

export const SideMenuList = [
    {
      name : 'Meeting Room & Desk',    // change name with precuation--- do search name  in all files before changing 
      selected : false ,
      disabled:false,
      hidden:true,
      sublist : [{
        name: 'Analytics',
        selected: false,
        disabled:false,
        hidden:false,
        url: 'analytics',
        fullUrl:'/admin-dash/analytics',
        title:'Analytical Insights | ${companyName} | Meeting Room Booking Interface'
      },{
        name : 'Qr Codes',
        selected : false ,
        url : 'qr-codes',
        disabled:false,
        hidden:false,
        fullUrl:'/admin-dash/qr-codes',
        title:'Office and floors QR Codes | ${companyName} | Meeting Room Booking Interface'
      },
      {
        name : 'Bookings',
        selected : false ,
        url : 'booking',
        disabled:false,
        hidden:false,
        fullUrl:'/admin-dash/booking',
        title:'Bookings Overview | ${companyName} | Meeting Room Booking Interface'
      },{
        name : 'Logs',
        selected : false ,
        url : 'logs',
        disabled:false,
        hidden:false,
        fullUrl:'/admin-dash/logs',
        title:'Booking Logs | ${companyName} | Meeting Room Booking Interface'
      },{
        name : 'Disable',
        selected : false ,
        disabled:false,
        hidden:false,
        url : 'disable',
        fullUrl:'/admin-dash/disable',
        title:'Disable User Screen | ${companyName} | Meeting Room Booking Interface'
      },
      {
        name : 'Booking Approval',
        selected : false ,
        url : 'booking-approval',
        disabled:false,
        hidden:true,
        fullUrl:'/admin-dash/booking-approval',
        title:'Approval based Bookings | ${companyName} | Meeting Room Booking Interface'
      },
      {
        name : 'Usage Matrix',
        selected : false ,
        url : 'meeting-room-usage-matrix',
        disabled:false,
        hidden:true,
        fullUrl:'/admin-dash/meeting-room-usage-matrix',
        title:'Usage Matrix | ${companyName} | Meeting Room Booking Interface'
      },
      {
        name : 'Update Inventory', // change name with precuation--- do search name  in all files before changing 
        selected : false ,
        disabled:false,
        hidden:false,
        url : 'edit-meeting-rooms-details',
        fullUrl:'/admin-dash/edit-meeting-rooms-details',
        title:"Add and edit your meeting rooms inventory | ${companyName}| India's No. 1 Enterprise SaaS"
      },
      ],
      productCode : 1,
      type:'product',
      icon:'https://ik.imagekit.io/qdesq/df7c8b9dc93438ca4691813a8c22339a_MwnzDkSj3.png'
    },
    {
      name : 'Check-In Management',  // change name with precuation--- do search name  in all files before changing 
      selected : false ,
      hidden:true,
      sublist : [{
        name : 'Analytics',
        selected : false ,
        disabled:false,
        hidden:false,
        url : 'checkin-analytics',
        fullUrl:'/admin-dash/checkin-analytics',
        title:'Analytical Insights | ${companyName} | Visitor Management System'
      },{
        name : 'Qr Codes',
        selected : false ,
        disabled:false,
        hidden:false,
        url : 'checkin-qr-codes',
        fullUrl:'/admin-dash/checkin-qr-codes',
        title:''
      },{
        name : 'Logs',
        selected : false ,
        disabled:false,
        hidden:false,
        url : 'checkin-logs',
        fullUrl:'/admin-dash/checkin-logs',
        title:'Check-in and check-out Logs  | ${companyName} | Visitor Management System'
      },
      {
        name : 'Visitor’s List',
        selected : false ,
        disabled:false,
        hidden:false,
        url : 'visitor-details',
        fullUrl:'/admin-dash/visitor-details',
        title:'Unique Visitors Details | ${companyName} | Visitor Management System'
      },{
        name : 'Blacklist',
        selected : false ,
        disabled:false,
        hidden:false,
        url : 'blacklist',
        fullUrl:'/admin-dash/blacklist',
        title:'Blacklist Visitors | ${companyName} | Visitor Management System'
      },
      // {
      //   name : 'Invite Logs',
      //   selected : false ,
      //   url : 'prebooking-logs',
      //   disabled:false,
      //   hidden:false,
      //   fullUrl:'/admin-dash/prebooking-logs'
      // }
      {
        name : 'Pre Invites',
        selected : false ,
        // url : 'prebooking-logs',
        disabled:false,
        hidden:false,
        // fullUrl:'/admin-dash/prebooking-logs'
        sublist:[
          {
            name : 'Send Invites',
            selected : false ,
            url : 'check-in-prebooking',
            disabled:false,
            hidden:false,
            fullUrl:'/admin-dash/check-in-prebooking',
            title:'Invite Guests | ${companyName} | Visitor Management System'
          },
          {
            name : 'Single Day Logs',
            selected : false ,
            url : 'prebooking-logs',
            disabled:false,
            hidden:false,
            fullUrl:'/admin-dash/prebooking-logs',
            title:'Single Day Invite Logs | ${companyName} |  Visitor Management System'
          },
          {
            name : 'Long Term Logs',
            selected : false ,
            url : 'long-term-logs',
            disabled:false,
            hidden:false,
            fullUrl:'/admin-dash/long-term-logs',
            title:'Multiple Day Invite Logs | ${companyName}  | Visitor Management System'
          }
        ]
      },
      {
        name : 'Visitor Approval',
        selected : false ,
        url : 'visitor-approval-requests',
        disabled:false,
        hidden:true,
        fullUrl:'/admin-dash/visitor-approval-requests',
        title:'Approve your visitors | ${companyName} | Visitor Management System'
      },
      {
        name : 'Approval Matrix',
        selected : false ,
        url : 'approval-matrix',
        disabled:false,
        hidden:true,
        fullUrl:'/admin-dash/approval-matrix',
        title:'Approve your visitors | ${companyName} | Visitor Management System'
      },
      {
        name : 'Check-In Details', // change name with precuation--- do search name  in all files before changing 
        selected : false ,
        disabled:false,
        hidden:false,
        url : 'edit-visitor-checkin-details',
        fullUrl:'/admin-dash/edit-visitor-checkin-details',
        title:"Add and edit your visitor management details  | ${companyName}  | India's No. 1 Enterprise SaaS"
      },
      ],
      productCode:2,
      type:'product',
      icon:'https://ik.imagekit.io/qdesq/0af8a1f9ec44d6743e8f2366c43fe47c_c8VKZw-c_.png'
    },
    {
      name : 'Feedback & Complaints', 
      selected : false ,
      sublist : [{
        name : 'Qr Codes',
        selected : false ,
        disabled:false,
        hidden:false,
        url : 'feedback-and-complaints/qr-codes',
        fullUrl:'/admin-dash/feedback-and-complaints/qr-codes',
        title:'${companyName} | Feedback and Compliant Box'
      },
      {
        name : 'Analytics',
        selected : false ,
        disabled:false,
        hidden:false,
        url : 'feedback-and-complaints/analytics',
        fullUrl:'/admin-dash/feedback-and-complaints/analytics',
        title:'Analytical insights | ${companyName}  | Feedback & Complaint Box'
      },
      {
        name : 'Logs ',
        selected : false ,
        disabled:false,
        hidden:false,
        sublist:[
          {
            name : 'Feedback logs',
            selected : false ,
            url : 'feedback-and-complaints/feedback-logs',
            disabled:false,
            hidden:false,
            fullUrl:'/admin-dash/feedback-and-complaints/feedback-logs',
            title:'My Logs | ${companyName}  | Feedback & Complaint Box'
          },
          {
            name : 'Complaint logs',
            selected : false ,
            url : 'feedback-and-complaints/complaint-logs',
            disabled:false,
            hidden:false,
            fullUrl:'/admin-dash/feedback-and-complaints/complaint-logs',
            title:'My Logs | ${companyName}  | Feedback & Complaint Box'
          },
        ]
      },
      {
        name : 'Work-flow Settings',
        selected : false ,
        disabled:false,
        hidden:false,
        sublist:[
          {
            name : 'Feedback',
            selected : false ,
            url : 'feedback-and-complaints/feedback-settings',
            disabled:false,
            hidden:false,
            fullUrl:'/admin-dash/feedback-and-complaints/feedback-settings',
            title:'Customisable forms | ${companyName} | Feedback & Complaint Box'
          },
          {
            name : 'Complaints',
            selected : false ,
            url : 'feedback-and-complaints/complaint-settings',
            disabled:false,
            hidden:false,
            fullUrl:'/admin-dash/feedback-and-complaints/complaint-settings',
            title:'Customisable forms | ${companyName} | Feedback & Complaint Box'
          },
        ]
      },
      {
        name : 'Auditors',
        selected : false ,
        disabled:false,
        hidden:false,
        url : 'feedback-and-complaints/auditors',
        fullUrl:'/admin-dash/feedback-and-complaints/auditors',
        title:'Auditors | ${companyName}  | Feedback & Complaint Box'
      },
      ],
      productCode:3,
      type:'product',
      hidden: true,
      icon:'https://ik.imagekit.io/qdesq/fcbf0e5a7d26c1a8bdb8da91ed5faf85_shc3AwTyY.png'
    },
    {
      name : 'User Roles',
      selected : false ,
      sublist : [
        {
          name : 'Office Admins',
          selected : false ,
          disabled:false,
          hidden:false,
          url : 'admins',
          fullUrl:'/admin-dash/admins',
          title:"Admins List | ${companyName} | Qudify | India's No. 1 Enterprise SaaS"
        },
        {
          name : 'Tenant Admins',   // change name with precuation--- do search name  in all files before changing 
          selected : false ,
          disabled:false,
          hidden:true,
          url : 'tenant-admins',
          fullUrl:'/admin-dash/tenant-admins',
          title:"Tenant Admins List | ${companyName} | Qudify | India's No. 1 Enterprise SaaS"
        },
        {
          name : 'Supervisor',   // change name with precuation--- do search name  in all files before changing 
          selected : false ,
          disabled:false,
          hidden:false,
          url : 'supervisor',
          fullUrl:'/admin-dash/supervisor',
          title:"Supervisors | ${companyName} | Qudify | India's No. 1 Enterprise SaaS"
        },
        {
          name : 'Verification Team',   // change name with precuation--- do search name  in all files before changing 
          selected : false ,
          disabled:false,
          hidden:false,
          url : 'security-team',
          fullUrl:'/admin-dash/security-team',
          title:"Verification Team List | ${companyName} | Qudify | India's No. 1 Enterprise SaaS"
        }
      ],
      icon:'https://ik.imagekit.io/qdesq/83d9d40c8afc0fcc6aee58eaff468f90_q4IENt3QB.png'
    },
    {
      name:'Employees',
      selected: false,
      sublist: [
        {
          name : 'Employee List',
          selected : false ,
          url : 'employees',
          disabled:false,
          hidden:false,
          fullUrl:'/admin-dash/employees',
          title:"Employee List | ${companyName} | India's No. 1 Enterprise SaaS"
        },
      ],
      icon:'https://ik.imagekit.io/qdesq/e15436abdb1871255d65774bfac30c45_s8uY5glCJ.png'
    },
    {
      name:'Account Settings',
      selected: false,
      url : 'Account Settings',
      disabled:false,
      hidden:false,
      fullUrl:'/admin-dash/account-settings',
      title:"Account Settings | ${companyName} |  India's No. 1 Enterprise SaaS",
      icon:'https://ik.imagekit.io/qdesq/83cc0e1d9d3600c2cca99ad7ee42b27a_xJSxgxVYS.png'
    },
    {
      name:'Attendance',
      selected: false,
      hidden:true,
      sublist: [
        {
          name : 'Employee Logs',
          selected : false ,
          url : 'employee-logs',
          disabled:false,
          hidden:false,
          fullUrl:'/admin-dash/employee-logs'
        },
        {
          name : 'Attendance Report',
          selected : false ,
          url : 'attendance-report',
          disabled:false,
          hidden:false,
          fullUrl:'/admin-dash/attendance-report'
        },
      ],
      icon:'https://ik.imagekit.io/qdesq/aef1b81244dd4fcc6e0df8b5c26f0b32_3hrzlit1y.png'
    },
    {
      name:'Broadcast Message',
      selected: false,
      hidden: true,
      sublist: [
        {
          name : 'Send Message',
          selected : false ,
          url : 'broadcast/send',
          disabled:false,
          hidden:false,
          fullUrl:'/admin-dash/broadcast/send'
        },
        {
          name : 'Message Logs',
          selected : false ,
          url : 'broadcast/logs',
          disabled:false,
          hidden:false,
          fullUrl:'/admin-dash/broadcast/logs'
        },
        {
          name : 'My Wallet',
          selected : false ,
          url : 'broadcast/wallet',
          disabled:false,
          hidden:false,
          fullUrl:'/admin-dash/broadcast/wallet'
        },
      ],
      icon:'https://ik.imagekit.io/qdesq/b09dda0383c41b9fcdf257f612101d3f_sOndJHc7H5.png'
    },
    {
      name : 'Edit',
      selected : false ,
      disabled:false,
      hidden:false,
      sublist : [{
        name : 'Organization Details', // change name with precuation--- do search name  in all files before changing 
        selected : false ,
        disabled:false,
        hidden:false,
        url : 'edit-organization-details',
        fullUrl:'/admin-dash/edit-organization-details',
        title:"Organisation Details | ${companyName} | India's No. 1 Enterprise SaaS"
      },
     
      

      ],
      icon:'https://ik.imagekit.io/qdesq/e76204e0e67752603bc332bd582a984f_6zFyMb08C.png'
    },
    
  ]
export const Time = [
  { hour:'0', min:'0', displayTime:'0:00 AM', value:0},
  { hour:'0', min:'30', displayTime:'0:30 AM', value:30},
  { hour:'1', min:'00', displayTime:'1:00 AM', value:100},
  { hour:'1', min:'30', displayTime:'1:30 AM', value:130},
  { hour:'2', min:'00', displayTime:'2:00 AM',value:200},
  { hour:'2', min:'30', displayTime:'2:30 AM',value:230},
  { hour:'3', min:'00', displayTime:'3:00 AM',value:300},
  { hour:'3', min:'30', displayTime:'3:30 AM',value:330},
  { hour:'4', min:'00', displayTime:'4:00 AM', value:400},
  { hour:'4', min:'30', displayTime:'4:30 AM', value:430},
  { hour:'5', min:'00', displayTime:'5:00 AM', value:500},
  { hour:'5', min:'30', displayTime:'5:30 AM',value:530},
  { hour:'6', min:'00', displayTime:'6:00 AM', value:600},
  { hour:'6', min:'30', displayTime:'6:30 AM', value:630},
  { hour:'7', min:'00', displayTime:'7:00 AM', value:700},
  { hour:'7', min:'30', displayTime:'7:30 AM', value:730},
  { hour:'8', min:'00', displayTime:'8:00 AM',value:800},
  { hour:'8', min:'30', displayTime:'8:30 AM',value:830},
  { hour:'9', min:'00', displayTime:'9:00 AM',value:900},
  { hour:'9', min:'30', displayTime:'9:30 AM',value:930},
  { hour:'10', min:'00', displayTime:'10:00 AM',value:1000},
  { hour:'10', min:'30', displayTime:'10:30 AM',value:1030},
  { hour:'11', min:'00', displayTime:'11:00 AM',value:1100},
  { hour:'11', min:'30', displayTime:'11:30 AM',value:1130},
  { hour:'12', min:'00', displayTime:'12:00 Noon',value:1200},
  { hour:'12', min:'30', displayTime:'12:30 PM',value:1230},
  { hour:'13', min:'00', displayTime:'1:00 PM',value:1300},
  { hour:'13', min:'30', displayTime:'1:30 PM',value:1330},
  { hour:'14', min:'00', displayTime:'2:00 PM',value:1400},
  { hour:'14', min:'30', displayTime:'2:30 PM',value:1430},
  { hour:'15', min:'00', displayTime:'3:00 PM',value:1500},
  { hour:'15', min:'30', displayTime:'3:30 PM',value:1530},
  { hour:'16', min:'00', displayTime:'4:00 PM',value:1600},
  { hour:'16', min:'30', displayTime:'4:30 PM',value:1630},
  { hour:'17', min:'00', displayTime:'5:00 PM',value:1700},
  { hour:'17', min:'30', displayTime:'5:30 PM',value:1730},
  { hour:'18', min:'00', displayTime:'6:00 PM',value:1800},
  { hour:'18', min:'30', displayTime:'6:30 PM',value:1830},
  { hour:'19', min:'00', displayTime:'7:00 PM',value:1900},
  { hour:'19', min:'30', displayTime:'7:30 PM',value:1930},
  { hour:'20', min:'00', displayTime:'8:00 PM',value:2000},
  { hour:'20', min:'30', displayTime:'8:30 PM',value:2030},
  { hour:'21', min:'00', displayTime:'9:00 PM',value:2100},
  { hour:'21', min:'30', displayTime:'9:30 PM',value:2130},
  { hour:'22', min:'00', displayTime:'10:00 PM',value:2200},
  { hour:'22', min:'30', displayTime:'10:30 PM',value:2230},
  { hour:'23', min:'00', displayTime:'11:00 PM',value:2300},
  { hour:'23', min:'30', displayTime:'11:30 PM',value:2330},
  { hour:'24', min:'00', displayTime:'12:00 AM',value:2400},
]
export const CountryCode =   [
  {
  name: "Afghanistan",
  dialCode: "93",
  code: "AF"
  },
  {
  name: "Aland Islands",
  dialCode: "358",
  code: "AX"
  },
  {
  name: "Albania",
  dialCode: "355",
  code: "AL"
  },
  {
  name: "Algeria",
  dialCode: "213",
  code: "DZ"
  },
  {
  name: "AmericanSamoa",
  dialCode: "1684",
 code: "AS"
  },
  {
  name: "Andorra",
  dialCode: "376",
  code: "AD"
  },
  {
  name: "Angola",
  dialCode: "244",
  code: "AO"
  },
  {
  name: "Anguilla",
  dialCode: "1264",
  code: "AI"
  },
  {
  name: "Antarctica",
  dialCode: "672",
  code: "AQ"
  },
  {
  name: "Antigua and Barbuda",
  dialCode: "1268",
  code: "AG"
  },
  {
  name: "Argentina",
  dialCode: "54",
  code: "AR"
  },
  {
  name: "Armenia",
  dialCode: "374",
  code: "AM"
  },
  {
  name: "Aruba",
  dialCode: "297",
  code: "AW"
  },
  {
  name: "Australia",
  dialCode: "61",
  code: "AU"
  },
  {
  name: "Austria",
  dialCode: "43",
  code: "AT"
  },
  {
  name: "Azerbaijan",
  dialCode: "994",
  code: "AZ"
  },
  {
  name: "Bahamas",
  dialCode: "1242",
  code: "BS"
  },
  {
  name: "Bahrain",
  dialCode: "973",
  code: "BH"
  },
  {
  name: "Bangladesh",
  dialCode: "880",
  code: "BD"
  },
  {
  name: "Barbados",
  dialCode: "1246",
  code: "BB"
  },
  {
  name: "Belarus",
  dialCode: "375",
  code: "BY"
  },
  {
  name: "Belgium",
  dialCode: "32",
  code: "BE"
  },
  {
  name: "Belize",
  dialCode: "501",
  code: "BZ"
  },
  {
  name: "Benin",
  dialCode: "229",
  code: "BJ"
  },
  {
  name: "Bermuda",
  dialCode: "1441",
  code: "BM"
  },
  {
  name: "Bhutan",
  dialCode: "975",
  code: "BT"
  },
  {
  name: "Bolivia, Plurinational State of",
  dialCode: "591",
  code: "BO"
  },
  {
  name: "Bosnia and Herzegovina",
  dialCode: "387",
  code: "BA"
  },
  {
  name: "Botswana",
  dialCode: "267",
  code: "BW"
  },
  {
  name: "Brazil",
  dialCode: "55",
  code: "BR"
  },
  {
  name: "British Indian Ocean Territory",
  dialCode: "246",
  code: "IO"
  },
  {
  name: "Brunei Darussalam",
  dialCode: "673",
  code: "BN"
  },
  {
  name: "Bulgaria",
  dialCode: "359",
  code: "BG"
  },
  {
  name: "Burkina Faso",
  dialCode: "226",
  code: "BF"
  },
  {
  name: "Burundi",
  dialCode: "257",
  code: "BI"
  },
  {
  name: "Cambodia",
  dialCode: "855",
  code: "KH"
  },
  {
  name: "Cameroon",
  dialCode: "237",
  code: "CM"
  },
  {
  name: "Canada",
  dialCode: "1",
  code: "CA"
  },
  {
  name: "Cape Verde",
  dialCode: "238",
  code: "CV"
  },
  {
  name: "Cayman Islands",
  dialCode: "345",
  code: "KY"
  },
  {
  name: "Central African Republic",
  dialCode: "236",
  code: "CF"
  },
  {
  name: "Chad",
  dialCode: "235",
  code: "TD"
  },
  {
  name: "Chile",
  dialCode: "56",
  code: "CL"
  },
  {
  name: "China",
  dialCode: "86",
  code: "CN"
  },
  {
  name: "Christmas Island",
  dialCode: "61",
  code: "CX"
  },
  {
  name: "Cocos (Keeling) Islands",
  dialCode: "61",
  code: "CC"
  },
  {
  name: "Colombia",
  dialCode: "57",
  code: "CO"
  },
  {
  name: "Comoros",
  dialCode: "269",
  code: "KM"
  },
  {
  name: "Congo",
  dialCode: "242",
  code: "CG"
  },
  {
  name: "Congo, The Democratic Republic of the Congo",
  dialCode: "243",
  code: "CD"
  },
  {
  name: "Cook Islands",
  dialCode: "682",
  code: "CK"
  },
  {
  name: "Costa Rica",
  dialCode: "506",
  code: "CR"
  },
  {
  name: "Cote d'Ivoire",
  dialCode: "225",
  code: "CI"
  },
  {
  name: "Croatia",
  dialCode: "385",
  code: "HR"
  },
  {
  name: "Cuba",
  dialCode: "53",
  code: "CU"
  },
  {
  name: "Cyprus",
  dialCode: "357",
  code: "CY"
  },
  {
  name: "Czech Republic",
  dialCode: "420",
  code: "CZ"
  },
  {
  name: "Denmark",
  dialCode: "45",
  code: "DK"
  },
  {
  name: "Djibouti",
  dialCode: "253",
  code: "DJ"
  },
  {
  name: "Dominica",
  dialCode: "1767",
  code: "DM"
  },
  {
  name: "Dominican Republic",
  dialCode: "1849",
  code: "DO"
  },
  {
  name: "Ecuador",
  dialCode: "593",
  code: "EC"
  },
  {
  name: "Egypt",
  dialCode: "20",
  code: "EG"
  },
  {
  name: "El Salvador",
  dialCode: "503",
  code: "SV"
  },
  {
  name: "Equatorial Guinea",
  dialCode: "240",
  code: "GQ"
  },
  {
  name: "Eritrea",
  dialCode: "291",
  code: "ER"
  },
  {
  name: "Estonia",
  dialCode: "372",
  code: "EE"
  },
  {
  name: "Ethiopia",
  dialCode: "251",
  code: "ET"
  },
  {
  name: "Falkland Islands (Malvinas)",
  dialCode: "500",
  code: "FK"
  },
  {
  name: "Faroe Islands",
  dialCode: "298",
  code: "FO"
  },
  {
  name: "Fiji",
  dialCode: "679",
  code: "FJ"
  },
  {
  name: "Finland",
  dialCode: "358",
  code: "FI"
  },
  {
  name: "France",
  dialCode: "33",
  code: "FR"
  },
  {
  name: "French Guiana",
  dialCode: "594",
  code: "GF"
  },
  {
  name: "French Polynesia",
  dialCode: "689",
  code: "PF"
  },
  {
  name: "Gabon",
  dialCode: "241",
  code: "GA"
  },
  {
  name: "Gambia",
  dialCode: "220",
  code: "GM"
  },
  {
  name: "Georgia",
  dialCode: "995",
  code: "GE"
  },
  {
  name: "Germany",
  dialCode: "49",
  code: "DE"
  },
  {
  name: "Ghana",
  dialCode: "233",
  code: "GH"
  },
  {
  name: "Gibraltar",
  dialCode: "350",
  code: "GI"
  },
  {
  name: "Greece",
  dialCode: "30",
  code: "GR"
  },
  {
  name: "Greenland",
  dialCode: "299",
  code: "GL"
  },
  {
  name: "Grenada",
  dialCode: "1473",
  code: "GD"
  },
  {
  name: "Guadeloupe",
  dialCode: "590",
  code: "GP"
  },
  {
  name: "Guam",
  dialCode: "1671",
  code: "GU"
  },
  {
  name: "Guatemala",
  dialCode: "502",
  code: "GT"
  },
  {
  name: "Guernsey",
  dialCode: "44",
  code: "GG"
  },
  {
  name: "Guinea",
  dialCode: "224",
  code: "GN"
  },
  {
  name: "Guinea-Bissau",
  dialCode: "245",
  code: "GW"
  },
  {
  name: "Guyana",
  dialCode: "595",
  code: "GY"
  },
  {
  name: "Haiti",
  dialCode: "509",
  code: "HT"
  },
  {
  name: "Holy See (Vatican City State)",
  dialCode: "379",
  code: "VA"
  },
  {
  name: "Honduras",
  dialCode: "504",
  code: "HN"
  },
  {
  name: "Hong Kong",
  dialCode: "852",
  code: "HK"
  },
  {
  name: "Hungary",
  dialCode: "36",
  code: "HU"
  },
  {
  name: "Iceland",
  dialCode: "354",
  code: "IS"
  },
  {
  name: "India",
  dialCode: "91",
  code: "IN"
  },
  {
  name: "Indonesia",
  dialCode: "62",
  code: "ID"
  },
  {
  name: "Iran, Islamic Republic of Persian Gulf",
  dialCode: "98",
  code: "IR"
  },
  {
  name: "Iraq",
  dialCode: "964",
  code: "IQ"
  },
  {
  name: "Ireland",
  dialCode: "353",
  code: "IE"
  },
  {
  name: "Isle of Man",
  dialCode: "44",
  code: "IM"
  },
  {
  name: "Israel",
  dialCode: "972",
  code: "IL"
  },
  {
  name: "Italy",
  dialCode: "39",
  code: "IT"
  },
  {
  name: "Jamaica",
  dialCode: "1876",
  code: "JM"
  },
  {
  name: "Japan",
  dialCode: "81",
  code: "JP"
  },
  {
  name: "Jersey",
  dialCode: "44",
  code: "JE"
  },
  {
  name: "Jordan",
  dialCode: "962",
  code: "JO"
  },
  {
  name: "Kazakhstan",
  dialCode: "77",
  code: "KZ"
  },
  {
  name: "Kenya",
  dialCode: "254",
  code: "KE"
  },
  {
  name: "Kiribati",
  dialCode: "686",
  code: "KI"
  },
  {
  name: "Korea, Democratic People's Republic of Korea",
  dialCode: "850",
  code: "KP"
  },
  {
  name: "Korea, Republic of South Korea",
  dialCode: "82",
  code: "KR"
  },
  {
  name: "Kuwait",
  dialCode: "965",
  code: "KW"
  },
  {
  name: "Kyrgyzstan",
  dialCode: "996",
  code: "KG"
  },
  {
  name: "Laos",
  dialCode: "856",
  code: "LA"
  },
  {
  name: "Latvia",
  dialCode: "371",
  code: "LV"
  },
  {
  name: "Lebanon",
  dialCode: "961",
  code: "LB"
  },
  {
  name: "Lesotho",
  dialCode: "266",
  code: "LS"
  },
  {
  name: "Liberia",
  dialCode: "231",
  code: "LR"
  },
  {
  name: "Libyan Arab Jamahiriya",
  dialCode: "218",
  code: "LY"
  },
  {
  name: "Liechtenstein",
  dialCode: "423",
  code: "LI"
  },
  {
  name: "Lithuania",
  dialCode: "370",
  code: "LT"
  },
  {
  name: "Luxembourg",
  dialCode: "352",
  code: "LU"
  },
  {
  name: "Macao",
  dialCode: "853",
  code: "MO"
  },
  {
  name: "Macedonia",
  dialCode: "389",
  code: "MK"
  },
  {
  name: "Madagascar",
  dialCode: "261",
  code: "MG"
  },
  {
  name: "Malawi",
  dialCode: "265",
  code: "MW"
  },
  {
  name: "Malaysia",
  dialCode: "60",
  code: "MY"
  },
  {
  name: "Maldives",
  dialCode: "960",
  code: "MV"
  },
  {
  name: "Mali",
  dialCode: "223",
  code: "ML"
  },
  {
  name: "Malta",
  dialCode: "356",
  code: "MT"
  },
  {
  name: "Marshall Islands",
  dialCode: "692",
  code: "MH"
  },
  {
  name: "Martinique",
  dialCode: "596",
  code: "MQ"
  },
  {
  name: "Mauritania",
  dialCode: "222",
  code: "MR"
  },
  {
  name: "Mauritius",
  dialCode: "230",
  code: "MU"
  },
  {
  name: "Mayotte",
  dialCode: "262",
  code: "YT"
  },
  {
  name: "Mexico",
  dialCode: "52",
  code: "MX"
  },
  {
  name: "Micronesia, Federated States of Micronesia",
  dialCode: "691",
  code: "FM"
  },
  {
  name: "Moldova",
  dialCode: "373",
  code: "MD"
  },
  {
  name: "Monaco",
  dialCode: "377",
  code: "MC"
  },
  {
  name: "Mongolia",
  dialCode: "976",
  code: "MN"
  },
  {
  name: "Montenegro",
  dialCode: "382",
  code: "ME"
  },
  {
  name: "Montserrat",
  dialCode: "1664",
  code: "MS"
  },
  {
  name: "Morocco",
  dialCode: "212",
  code: "MA"
  },
  {
  name: "Mozambique",
  dialCode: "258",
  code: "MZ"
  },
  {
  name: "Myanmar",
  dialCode: "95",
  code: "MM"
  },
  {
  name: "Namibia",
  dialCode: "264",
  code: "NA"
  },
  {
  name: "Nauru",
  dialCode: "674",
  code: "NR"
  },
  {
  name: "Nepal",
  dialCode: "977",
  code: "NP"
  },
  {
  name: "Netherlands",
  dialCode: "31",
  code: "NL"
  },
  {
  name: "Netherlands Antilles",
  dialCode: "599",
  code: "AN"
  },
  {
  name: "New Caledonia",
  dialCode: "687",
  code: "NC"
  },
  {
  name: "New Zealand",
  dialCode: "64",
  code: "NZ"
  },
  {
  name: "Nicaragua",
  dialCode: "505",
  code: "NI"
  },
  {
  name: "Niger",
  dialCode: "227",
  code: "NE"
  },
  {
  name: "Nigeria",
  dialCode: "234",
  code: "NG"
  },
  {
  name: "Niue",
  dialCode: "683",
  code: "NU"
  },
  {
  name: "Norfolk Island",
  dialCode: "672",
  code: "NF"
  },
  {
  name: "Northern Mariana Islands",
  dialCode: "1670",
  code: "MP"
  },
  {
  name: "Norway",
  dialCode: "47",
  code: "NO"
  },
  {
  name: "Oman",
  dialCode: "968",
  code: "OM"
  },
  {
  name: "Pakistan",
  dialCode: "92",
  code: "PK"
  },
  {
  name: "Palau",
  dialCode: "680",
  code: "PW"
  },
  {
  name: "Palestinian Territory, Occupied",
  dialCode: "970",
  code: "PS"
  },
  {
  name: "Panama",
  dialCode: "507",
  code: "PA"
  },
  {
  name: "Papua New Guinea",
  dialCode: "675",
  code: "PG"
  },
  {
  name: "Paraguay",
  dialCode: "595",
  code: "PY"
  },
  {
  name: "Peru",
  dialCode: "51",
  code: "PE"
  },
  {
  name: "Philippines",
  dialCode: "63",
  code: "PH"
  },
  {
  name: "Pitcairn",
  dialCode: "872",
  code: "PN"
  },
  {
  name: "Poland",
  dialCode: "48",
  code: "PL"
  },
  {
  name: "Portugal",
  dialCode: "351",
  code: "PT"
  },
  {
  name: "Puerto Rico",
  dialCode: "1939",
  code: "PR"
  },
  {
  name: "Qatar",
  dialCode: "974",
  code: "QA"
  },
  {
  name: "Romania",
  dialCode: "40",
  code: "RO"
  },
  {
  name: "Russia",
  dialCode: "7",
  code: "RU"
  },
  {
  name: "Rwanda",
  dialCode: "250",
  code: "RW"
  },
  {
  name: "Reunion",
  dialCode: "262",
  code: "RE"
  },
  {
  name: "Saint Barthelemy",
  dialCode: "590",
  code: "BL"
  },
  {
  name: "Saint Helena, Ascension and Tristan Da Cunha",
  dialCode: "290",
  code: "SH"
  },
  {
  name: "Saint Kitts and Nevis",
  dialCode: "1869",
  code: "KN"
  },
  {
  name: "Saint Lucia",
  dialCode: "1758",
  code: "LC"
  },
  {
  name: "Saint Martin",
  dialCode: "590",
  code: "MF"
  },
  {
  name: "Saint Pierre and Miquelon",
  dialCode: "508",
  code: "PM"
  },
  {
  name: "Saint Vincent and the Grenadines",
  dialCode: "1784",
  code: "VC"
  },
  {
  name: "Samoa",
  dialCode: "685",
  code: "WS"
  },
  {
  name: "San Marino",
  dialCode: "378",
  code: "SM"
  },
  {
  name: "Sao Tome and Principe",
  dialCode: "239",
  code: "ST"
  },
  {
  name: "Saudi Arabia",
  dialCode: "966",
  code: "SA"
  },
  {
  name: "Senegal",
  dialCode: "221",
  code: "SN"
  },
  {
  name: "Serbia",
  dialCode: "381",
  code: "RS"
  },
  {
  name: "Seychelles",
  dialCode: "248",
  code: "SC"
  },
  {
  name: "Sierra Leone",
  dialCode: "232",
  code: "SL"
  },
  {
  name: "Singapore",
  dialCode: "65",
  code: "SG"
  },
  {
  name: "Slovakia",
  dialCode: "421",
  code: "SK"
  },
  {
  name: "Slovenia",
  dialCode: "386",
  code: "SI"
  },
  {
  name: "Solomon Islands",
  dialCode: "677",
  code: "SB"
  },
  {
  name: "Somalia",
  dialCode: "252",
  code: "SO"
  },
  {
  name: "South Africa",
  dialCode: "27",
  code: "ZA"
  },
  {
  name: "South Georgia and the South Sandwich Islands",
  dialCode: "500",
  code: "GS"
  },
  {
  name: "Spain",
  dialCode: "34",
  code: "ES"
  },
  {
  name: "Sri Lanka",
  dialCode: "94",
  code: "LK"
  },
  {
  name: "Sudan",
  dialCode: "249",
  code: "SD"
  },
  {
  name: "Suriname",
  dialCode: "597",
  code: "SR"
  },
  {
  name: "Svalbard and Jan Mayen",
  dialCode: "47",
  code: "SJ"
  },
  {
  name: "Swaziland",
  dialCode: "268",
  code: "SZ"
  },
  {
  name: "Sweden",
  dialCode: "46",
  code: "SE"
  },
  {
  name: "Switzerland",
  dialCode: "41",
  code: "CH"
  },
  {
  name: "Syrian Arab Republic",
  dialCode: "963",
  code: "SY"
  },
  {
  name: "Taiwan",
  dialCode: "886",
  code: "TW"
  },
  {
  name: "Tajikistan",
  dialCode: "992",
  code: "TJ"
  },
  {
  name: "Tanzania, United Republic of Tanzania",
  dialCode: "255",
  code: "TZ"
  },
  {
  name: "Thailand",
  dialCode: "66",
  code: "TH"
  },
  {
  name: "Timor-Leste",
  dialCode: "670",
  code: "TL"
  },
  {
  name: "Togo",
  dialCode: "228",
  code: "TG"
  },
  {
  name: "Tokelau",
  dialCode: "690",
  code: "TK"
  },
  {
  name: "Tonga",
  dialCode: "676",
  code: "TO"
  },
  {
  name: "Trinidad and Tobago",
  dialCode: "1868",
  code: "TT"
  },
  {
  name: "Tunisia",
  dialCode: "216",
  code: "TN"
  },
  {
  name: "Turkey",
  dialCode: "90",
  code: "TR"
  },
  {
  name: "Turkmenistan",
  dialCode: "993",
  code: "TM"
  },
  {
  name: "Turks and Caicos Islands",
  dialCode: "1649",
  code: "TC"
  },
  {
  name: "Tuvalu",
  dialCode: "688",
  code: "TV"
  },
  {
  name: "Uganda",
  dialCode: "256",
  code: "UG"
  },
  {
  name: "Ukraine",
  dialCode: "380",
  code: "UA"
  },
  {
  name: "United Arab Emirates",
  dialCode: "971",
  code: "AE"
  },
  {
  name: "United Kingdom",
  dialCode: "44",
  code: "GB"
  },
  {
  name: "United States",
  dialCode: "1",
  code: "US"
  },
  {
  name: "Uruguay",
  dialCode: "598",
  code: "UY"
  },
  {
  name: "Uzbekistan",
  dialCode: "998",
  code: "UZ"
  },
  {
  name: "Vanuatu",
  dialCode: "678",
  code: "VU"
  },
  {
  name: "Venezuela, Bolivarian Republic of Venezuela",
  dialCode: "58",
  code: "VE"
  },
  {
  name: "Vietnam",
  dialCode: "84",
  code: "VN"
  },
  {
  name: "Virgin Islands, British",
  dialCode: "1284",
  code: "VG"
  },
  {
  name: "Virgin Islands, U.S.",
  dialCode: "1340",
  code: "VI"
  },
  {
  name: "Wallis and Futuna",
  dialCode: "681",
  code: "WF"
  },
  {
  name: "Yemen",
  dialCode: "967",
  code: "YE"
  },
  {
  name: "Zambia",
  dialCode: "260",
  code: "ZM"
  },
  {
  name: "Zimbabwe",
  dialCode: "263",
  code: "ZW"
  }
]



export const weekDays = ['sun','mon','tue','wed','thu','fri','sat']
export const genericDomains = [ 'zoho', 'gmail.com', 'yahoo.com', 'yahoo.co.in', 'yahoo.in', 'rediffmail.com','outlook.com', 'hotmail.com', 'hotmail.in','ymail.com']

export const loginPageContent : any = [{
  quote : "The best way to predict your future is to create it",
  imageLink : "https://qdesq.imagekit.io/image/upload/v1641815575/aen0z3p69190gpntwddo.png"
},{
  quote : "The most effective way to do it, is to do it",
  imageLink : "https://qdesq.imagekit.io/img/image/upload/v1641815330/athkufjjzph8nplwk9qm.png"
},{
  quote : "We will either find a way or make one",
  imageLink : "https://qdesq.imagekit.io/image/upload/v1641815761/on07qis8x2et1bvdugxk.png"
},{
  quote : "you’re not going fast enough",
  imageLink : "https://qdesq.imagekit.io/image/upload/v1641815876/rpt8fc9fbtcptgxonimp.png"
},
{
  quote : "Don’t count the days, make the days count",
  imageLink : "https://qdesq.imagekit.io/image/upload/v1641815936/pjm4wx3bwbzkbtghg2tv.png"
},
{
  quote : "If everything seems under control, you’re not going fast enough",
  imageLink : "https://qdesq.imagekit.io/image/upload/v1641816051/epg6lakgh6tz68mswe0a.png"
},
{
  quote : "you’re not going fast enough",
  imageLink : "https://qdesq.imagekit.io/img/image/upload/v1650619583/epl2f2aoph53reoigquq.jpg"
},
]
export const broadcastCompanyIds = ['6501a91840420251ca640bfa','6368de9abbb0fd6360ff64a5','6345588a84240049883b50cb','61dbebf96dffb6300e103f23','64896da604d4d71e373598ec']

export const loginWithMicrosoftCompanies = ['65169c12eb2c20f30490053c','64c8ad2fa9268d69edfc92d4','61dbebf96dffb6300e103f23','61de71b92a066c419129e187', '6513ffbbd8d6a7101b53a51c']
export const oktaLoginUrls = [
  {
    companyId:'6549d073d014c117d329166b', // test jll
    loginUrl:'https://applogin-uat.jll.com/app/jllpoc_qudifytestuatsamlad_1/exk1va2l1tatCu69j0h8/sso/saml',
    domains:[]
  },
  {
    companyId:'64896da604d4d71e373598ec',    // live jll
    loginUrl:'https://applogin.jll.com/app/jll_qudifysamlad_1/exk1u3wmqik6K6cp90h8/sso/saml',
    domains:['jll.com']
  },
  {
    companyId:'61de71b92a066c419129e187',
    loginUrl:'https://dev-50888533.okta.com/app/dev-50888533_newtestapp_1/exkcz0pgekirSxxfy5d7/sso/saml',
    domains:['dfdsf.dsf','qdesq.com']
  }
]

export const PlakshSpecificPassRelatedChangesCompanyIds = ['64c8ad2fa9268d69edfc92d4','6568703a7410e77deb4ff13f']
// {
//   "name": "Asia/Kolkata",
//   "alternativeName": "India Time",
//   "group": [
//     "Asia/Kolkata",
//     "Asia/Calcutta"
//   ],
//   "continentCode": "AS",
//   "continentName": "Asia",
//   "countryName": "India",
//   "countryCode": "IN",
//   "mainCities": [
//     "Mumbai",
//     "Delhi",
//     "Bengaluru",
//     "Hyderābād"
//   ],
//   "rawOffsetInMinutes": 330,
//   "abbreviation": "IST",
//   "rawFormat": "+05:30 India Time - Mumbai, Delhi, Bengaluru, Hyderābād"
// },

export const ignoreLoginNavigationUrls = ['visitor-checkin?','visitor-checkout?','visitor-prebooking','security-team-pass-verification','employee-access-view','feedback-and-complaints']
export const employeesCanPreInviteVisitorsFromLoginPage = ['641aaeb73eb544647eaf68b6','656f3f61b0e91a0388b20720','64f56dfd47116e42e4282847']
export const contactNumberOptionalInAddEmplpoyee = ['660baa91d87d3e03bd8ff33c', '66056300b5c25942f3819884', '659d264bf3baa403686d05e7']
export const conditionalWalkinPassInputFiedInVisitorDetails = [
  {
    companyId:'660baa91d87d3e03bd8ff33c',
    officeId:'660bab3ad87d3e03bd8ff360',
    fieldReferenceName:'assetID',
    condition: {
      referenceName:"carryingAnyDigitalAsset",
      value:'yes'
    }
  },
  {
    companyId:'64896da604d4d71e373598ec',
    officeId:'6489734604d4d71e3735add7',
    fieldReferenceName:'acc.Details',
    condition: {
      referenceName:"accessories",
      value:'yes'
    }
  },
  {
    companyId:'64896da604d4d71e373598ec',
    officeId:'6582ccc0c1ed403381eb2bd4',
    fieldReferenceName:'accessoryDescription',
    condition: {
      referenceName:"carryingAnyAccessory",
      value:'yes'
    }
  }
]
export const captureCompaignParameters = [
  {
    urlKey:'utm_medium',
    leadkey:'utmMedium'
  },
  {
    urlKey:'utm_campaign',
    leadkey:'utmCampaign'
  },
  {
    urlKey:'utm_term',
    leadkey:'utmTerm'
  },
  {
    urlKey:'utm_source',
    leadkey:'utmSource'
  },
  {
    urlKey:'fbclid',
    leadkey:'fbclid'
  },
  {
    urlKey:'li_fat_id',
    leadkey:'linkedInFatId'
  }

]
export const companyIdsToEnableVIPPassForEmployeesInPreInvite = ['660baa91d87d3e03bd8ff33c','65a12920ab973878a90acc77']

export const RegencyNirmanClientSpecificChanges = ['661e5d568bad7c6bf16eb492','65685c5ce3a5046fb08053f3']

export const CompanyIdsWithDepartmentOptionalInPreInviteCompanyPass = ['660baa91d87d3e03bd8ff33c','64896da604d4d71e373598ec','6513ffbbd8d6a7101b53a51c']

export const CompanyIdsToDisplayLogoWithPoweredBy = ['6645db3e33b5d437db135dc8', '66265861c6c6c99b709f952d']

export const CompanyIdsToDisplayVisitorContactDetailsInPass = ['658e7902393ffd03bd9f2237','6486b904c5faff0ff5e94e90'] 
export const CompanyIdsForLloydsClientSpecificChanges = ['658e7902393ffd03bd9f2237','64f056d4bbac654ac9c612d1']
export const CompanyIdsForOphiliaClientSpecificChanges = ['645e32c896c6ff59c0b70895', '64b62f817989b17df998fee6','651e96d90fd30c8886c0b4b3', '664d950fc9ff85323cc49898', '656f3f61b0e91a0388b20720']
export const CompanyIdsToHideQRCodeInVMSCheckInApplication = ['664d950fc9ff85323cc49898', '651e96d90fd30c8886c0b4b3']
export const CompanyIdToHideDepartmentInWalkinVisitorFlow = ['650979601090d3c42c45d3be','6658452ed0da2e175e794823','649d5a19ea6b96b8889b488c','66b1f54e1d87742494881ba7','65e1d9a42e0df261912dd860']
export const CompanyIdToHidePOCDetailsInWalkinVisitorFlowAndPass = ['650979601090d3c42c45d3be','6658452ed0da2e175e794823']
export const DaysLimitOnCustomMeetingRoomBookings = [
  {
    companyId:'657a8b594d09481c9552f75c',
    days:10        
  },
  {
    companyId:'65b0f7d2ccfa9cad2298f095',
    days:10
  },
  {
    companyId:'6532a9b78a3d1522ac78b231',
    days:10
  },
  {
    companyId:'6745aa404a3b2f424b9b186b',
    days:5
  },
  {
    companyId:'64cc9651b039c564d80385d0',
    days:5
  },
  {
    companyId:'67483b9c1fcd6d495962a2f8',
    days:5
  },
]
export const CleintWiseCustomMeetingRoomBookingOptions = [
  {
    companyId:'657a8b594d09481c9552f75c',
    options: [
      {
        name: 'Do not repeat',
        value:'do not repeat'
      },
      {
        name: 'Daily',
        value:'daily'
      },
      {
        name: 'Weekly on ',
        value:'weekly'
      },
      {
        name: 'Custom',
        value:'custom'
      }
    ]        
  },
  {
    companyId:'65b0f7d2ccfa9cad2298f095',
    options: [
      {
        name: 'Do not repeat',
        value:'do not repeat'
      },
      {
        name: 'Daily',
        value:'daily'
      },
      {
        name: 'Weekly on ',
        value:'weekly'
      },
      {
        name: 'Custom',
        value:'custom'
      }
    ]
  },
  {
    companyId:'6532a9b78a3d1522ac78b231',
    options: [
      {
        name: 'Do not repeat',
        value:'do not repeat'
      },
      {
        name: 'Daily',
        value:'daily'
      },
      {
        name: 'Weekly on ',
        value:'weekly'
      },
      {
        name: 'Custom',
        value:'custom'
      }
    ]
  }
]

export const CompanyIdsToDisableRecurringMeetingRoomBookings = ['652fc710e349446e23d08933', '628b62014562751b8cc225fd', '64cc9651b039c564d80385d0','6745aa404a3b2f424b9b186b', '67483b9c1fcd6d495962a2f8' ]
export const CompanyIdsForLoginWithEmailOnlyInPreInviteFlow = ['64e48fcd04a6c9484bb7cdfa', '665af5281270d57b1a0f3bfd'];

export const ExtendWalkinPassValidityTimeFromCurrentDate = [
  {
    companyId:'66629bd25583aa6ff695fc3b',   // cloud9 events
    daysExtended: 5
  },
  {
    companyId:'6500165acc520f66f5089f78',   // test
    daysExtended: 5
  },
  {
    companyId:'6532d0522a021b4acde14810',   // live demo
    daysExtended: 5
  }
]

export const InternetOnOffModel = ['display-meeting-room-bookings', 'visitor-checkin?refId', 'visitor-checkout?refId']

export const RedirectToDynamicFeedbacksAndShowOnlyFeedback = ['64b9192c95c7ab54feacaa46','6703d5e3026872349c8a8d96']

export const bookingApprovalCapCustomizationsWithBookingApprovalAddonAdded = [      // MR approval will not be sent if mentioned Booking duration is less thaan mentioned slots

  {
    companyId:'6745aa404a3b2f424b9b186b',   // test Mama Earth 2
    numberOfSlots:4
  },
  {
    companyId:'64cc9651b039c564d80385d0', // live mama earth
    numberOfSlots:4
  },
  {
    companyId:'67483b9c1fcd6d495962a2f8', // live demo  Test King Pvt.
    numberOfSlots:4
  }


]
export const constSessionCredentials = {
  secretv:'d43cb98ef0c1a25e32f461573f68a9a1',
  secretKey:'vOVF6sdmpNWjRRIqCc7rdxs01lwHzfr3'

}
