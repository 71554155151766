import { Component, OnInit, Inject, Optional, Injector,ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConfirmationModalComponent implements OnInit {

  constructor(  
    @Optional() public dialogRef: MatDialogRef<ConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
    img:any
  ngOnInit(): void {
    console.log('dtaaaa',this.data);
    if(this.data.action == 'update' || this.data.action == 'add'){
      this.img = 'https://ik.imagekit.io/qdesq/b2881c584027f67025e26fb5d3d5e111_GPpo99Uai.png'
    }
    if(this.data.image) {
      this.img = this.data.image
    }

    if(this.data.action == 'delete'){
      this.img = 'https://ik.imagekit.io/qdesq/dd1ccb6fd77c9d5d6f56b85e7900e714_M_zJuBowl.png'
    }
    if(this.data.action == 'error'){
      this.img = 'https://qdesq.imagekit.io/image/upload/v1608641680/htaahynkcx1bzj8tina7.svg'
    }
    
  }

}
