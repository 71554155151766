import { Injectable } from '@angular/core'
import { CompanyDetailsService } from '../@services/company-details.service'
import { genericDomains } from './commonData'
import * as moment from 'moment-timezone'
import { Constants } from './constants'
import { ApiService } from '../@services/api.service'
import { Validation } from './validation'


@Injectable({
    providedIn: 'root'
})
  export class commonFunctions {
    constructor(
      private companyService:CompanyDetailsService,
      private api : ApiService,
      private validation: Validation
    ) {

    }
    beautyEncode(string) {
      if (string != undefined && string != null && string != '') {
        string = string.replace(/ /g, '-')
        string = string.replace(/,|&/g, '')
        string = string.replace(/\//, '')
        return string
      }
    }
    getPolicy(companyId){
        if(companyId == '6305c0f52319512dede1f3d5'){
            return `<ol><li>Visitors are allowed only to office area.</li><li>Access to plant area only with prior approval.</li><li>You are in a flammable zone. Lighter, Matchbox or any inflammable items are strictly prohibited.</li><li>Mobile phone is prohibited.</li><li>Photography is&nbsp;prohibited.</li><li>No smoking Area.</li><li>No Chewing of tobacco &amp; chewing gums.</li><li>No entry if you have communicable disease like Typhoid, Cholera, fever etc.</li><li>Visitors are kindly requested to complete the Visitors Health questionnaire.</li></ol>`
        }else{
            return '<ol><li>Please make sure that all information provided is correct.</li><li>Kindly follow covid appropriate behaviour.</li><li>Maintain social distancing and always wear a mask.</li></ol>'
        }
    }
    
    capitalizeFirstLetter(name){
    if(name && name.length>0){
        var nameArray = [];
        nameArray = name.split(' ');
        for(var i=0;i<nameArray.length;i++){
            nameArray[i] = nameArray[i].substr(0,1).toUpperCase() + nameArray[i].substr(1);
        }
        return nameArray.join(" ");
    }
     }
    getCategory(i){
      if(i == "1"){
        return "I Work Here"
      }else if(i == "2"){
        return "Meeting Appointment"
      }else if(i == "3"){
        return "Delivery"
      }else if(i == "4"){
        return "Service"
      }else if(i == "5"){
        return "Other"
      }else if(i == "6"){
        return "Company"
      }else if(i == "7"){
        return "VIP"
      }else if(i == "8"){
        return "Access Pass"
      }
    }
    getCategoryPassConfiguration(i,passConfiguration){
      if(i==8){
        return "Access"
      }else{
        let category = passConfiguration.find((ele)=> JSON.stringify(ele.code) == i)
        if(category){
          return category.displayName
        }
      }
   
    }
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    }

    // todo : restrictions for sub menus for admins
    getMeetingRoomSubMenues(){
      return [
        {
          name:'Analytics',
          id:'analytics',
          url:'admin-dash/analytics',
          hasPermission:false
        },
        {
          name:'Qr Codes',
          id:'qr_codes',
          url:'admin-dash/analytics',
          hasPermission:false
        }
      ]
    }

    getOfficeList(offices, permissionDetails){
      
      let officeList = [];
      if(!permissionDetails || !permissionDetails.currentPermission['officeIds']){
        return officeList;
      }
      if(offices && offices.length>0){
        offices.forEach(element => {
          if(element.isActive){
            if(permissionDetails.roles && permissionDetails.roles.indexOf('Super Admin')>=0){
              officeList.push(element)
            }
            else if(permissionDetails.currentPermission['officeIds'].indexOf(element._id)>=0){
              officeList.push(element)
            }
            // element.productList  = this.getProductList(element)
          }
          
        });
      }
      return officeList
    }
    getImagekitUrl(img) {
      var str = ''
      if (img) str = img.replace('http://res.cloudinary.com/qdesqtest', 'https://qdesq.imagekit.io')
      return str
    }

    isDomainAllowed(email, officeId = null, checkAllOffices = false, company = null): boolean{
      if(!email){
        return;
      }

      let domain = email.split('@')[1]
      let companyDetails = this.companyService.companyDetails;
      
      if(!companyDetails){
        companyDetails = company;
      }
      if(!companyDetails){
        return false;
      }

      if(companyDetails && companyDetails.type && companyDetails.type.toLowerCase().includes('coworking')){
        return true
      }

      console.log('companyDetails====',companyDetails);
      
      if(companyDetails.id == '63eb3b41e0058b20d1f25a0e' || companyDetails.id == '63525948abaed853fca3771a'){
        if(genericDomains.indexOf(domain)>=0){
          return true;
        }
      }
      if(companyDetails.createdBy.split('@')[1].trim() == domain){
        return true;
      }

      if(companyDetails.domainName == domain){
        return true;
      }
      // if(companyDetails.type && companyDetails.type.indexOf('coworking')>=0){
      //   if(companyDetails.additionalDomains && companyDetails.additionalDomains.length<=0){
      //     return true
      //   }
      // }
      let isForAllOffices = companyDetails.additionalDomains && companyDetails.additionalDomains.length>0 ? companyDetails.additionalDomains[0].forAllOffices : false
      
      
      let allDomainList = [];
      
      console.log('allOfficeDomains----',allDomainList)
      if(isForAllOffices || checkAllOffices){
        let domainsArray = companyDetails.additionalDomains.map(item => item.domainsList.map(item => item.domain));
        for(let item of domainsArray){
          allDomainList.push(...item)
        }
        
        if(allDomainList.indexOf(domain)>=0){
          return true;
        }else{
          return false;
        }
      
      }

      

      if(officeId){
        let officeDomains = companyDetails.additionalDomains && companyDetails.additionalDomains.length>0 ? companyDetails.additionalDomains.find(item => item.officeId == officeId) : null

        if(officeDomains){
          officeDomains = officeDomains.domainsList
          officeDomains = officeDomains.map(item => item.domain);
        }else{
          officeDomains = []
        }

        

        if(officeDomains.indexOf(domain)>=0){
          return true
        }else{
          return false;
        }

      }

      // check for super admin
      

      console.log('company details ==> ', this.companyService.companyDetails)
      return false
    }

    getOfficeIdsOfDomain(email, company = null, domainName = null):any {
      console.log('email====',email,this.companyService.companyDetails.domainName)
      if(!email){
        return false;
      }
      let domain = domainName ? domainName : email.toLowerCase().trim().split('@')[1]
      let companyDetails = this.companyService.companyDetails;
      if(!companyDetails){
        companyDetails = company
      }
      if(!companyDetails){
        return false
      }
      if(companyDetails.domainName == domain){
        return null;
      }
      if(companyDetails.createdBy && companyDetails.createdBy.split('@')[1] == domain){
        return null;
      }
      if(companyDetails.additionalDomains && companyDetails.additionalDomains.length>0){
        let officeIds = []
        for(let list of companyDetails.additionalDomains){
          for(let domainName of list.domainsList){
            if(domainName.domain == domain){
              if(list.forAllOffices){
                return null;
              }
              officeIds.push(list.officeId)
            }
          }
        }
        if(officeIds && officeIds.length>0){
          return officeIds
        }else{
          return false;
        }

      }

      return false

    }

    validateUserForAdminAccess(email){
      if(!email){
        return false;
      }
      let domain = email.toLowerCase().trim().split('@')[1]
      let companyDetails = this.companyService.companyDetails;
      if(companyDetails.domainName == domain){
        return null;
      }
    }
    getVisitCategories(categories){
      // let categories = [1,2,4]
      let dat = [
        {
          type:1,
          reason: 'I work here',
          selected: false
        },
        {
          type:2,
          reason:'Meeting Appointment',
          selected: false
        },
        {
          type:3,
          reason: 'Delivery',
          selected: false
        },
        {
          type:4,
          reason:'Service',
          selected: false
        },
        {
          type:5,
          reason:'Other',
          selected: false
        }
      ]
      let temp = []
      dat.forEach(element => {
        if(categories.indexOf(element.type)>-1){
          temp.push(element)
        }
      });
      console.log(temp)
      return temp
    }
    getVisitPurpose(categories){
      let temp = []
      categories.forEach(element => {
        if(element.isActive){
          element['selected'] = false
          temp.push(element)
        }
      });
      return temp
    }

    loadJquery(){
      let head = document.getElementsByTagName('HEAD')[0];
  
      let link = document.createElement('script');
      let link1 = document.createElement('script');
  
      // set the attributes for link element
      // link.rel = 'stylesheet';
      link.type = 'text/javascript';
      link1.type = 'text/javascript';
      link.src = 'https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js';
      link1.src = 'https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js';
  
      // Append link element to HTML head
      head.appendChild(link);
      head.appendChild(link1);
  }
  loadBootstrapCss() {
    let head = document.getElementsByTagName('HEAD')[0];
  
    let link = document.createElement('link');
    link.rel = 'stylesheet';
   
    link.href = 'https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css';
   
    // Append link element to HTML head
    head.appendChild(link);
  }
  loadJquery3dot7(){
    let head = document.getElementsByTagName('HEAD')[0];

    let link = document.createElement('script');
    let link1 = document.createElement('script');

    // set the attributes for link element
    // link.rel = 'stylesheet';
    link.type = 'text/javascript';
    link1.type = 'text/javascript';
    link.src = 'https://ajax.googleapis.com/ajax/libs/jquery/3.7.1/jquery.min.js';
    link1.src = 'https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js';

    // Append link element to HTML head
    head.appendChild(link);
    head.appendChild(link1);
}
  getOfficesForProduct(offices, productCode=null){
    if(!offices){
      return [];
    }

    if(!productCode){
      return offices.filter(item => item.isActive && item.products && Array.isArray(item.products) && item.products.filter(item => item.isActive).length>0 && (( item.isBulding==true && (!item.mergeObj || !item.mergeObj.status)) ||  !item.isBulding));
    }
    const officeList = []
    
    for(let i=0;i<offices.length;i++){

      const office = offices[i];

      if(office.isActive){

        if(office.isBulding==true && office.mergeObj && office.mergeObj.status){
          continue;
        }

        if(office.products && office.products.length > 0){

          let productExists = office.products.find(product => product.productCode == productCode && product.isActive);

          if(productExists){
            if(productCode == 1 && this.checkOfficeAllFloorsIsActive(office.floorDetails)){
              officeList.push(office);
            }
            if(productCode == 2){
              officeList.push(office);
            }
            if(productCode == 3 || productCode == 4){
              officeList.push(office);
            }
            // if(productCode == 4){
            //   officeList.push(office);
            // }
            continue;
          }

        }else{

          if(productCode == 1 && !office.isBulding && this.checkOfficeAllFloorsIsActive(office.floorDetails)){
            officeList.push(office);
          }

          if(productCode == 2 && office.isBulding){
            officeList.push(office);
          }

        }



      }

    }

    return officeList;

  }
  getAllOfficesForProduct(offices, productCode=null){
    if(!offices){
      return [];
    }

    if(!productCode){
      return offices.filter(item => item.products && Array.isArray(item.products) && item.products.filter(item => item.isActive).length>0 && (( item.isBulding==true && (!item.mergeObj || !item.mergeObj.status)) ||  !item.isBulding));
    }
    const officeList = []
    
    for(let i=0;i<offices.length;i++){

      const office = offices[i];

      // if(office.isActive){

        if(office.isBulding==true && office.mergeObj && office.mergeObj.status){
          continue;
        }

        if(office.products && office.products.length > 0){

          let productExists = office.products.find(product => product.productCode == productCode && product.isActive);

          if(productExists){
            if(productCode == 1 && this.checkOfficeAllFloorsIsActive(office.floorDetails)){
              officeList.push(office);
            }
            if(productCode == 2){
              officeList.push(office);
            }
            continue;
          }

        }else{

          if(productCode == 1 && !office.isBulding && this.checkOfficeAllFloorsIsActive(office.floorDetails)){
            officeList.push(office);
          }

          if(productCode == 2 && office.isBulding){
            officeList.push(office);
          }

        }



      // }

    }

    return officeList;

  }

  checkOfficeAllFloorsIsActive(dat){
    let temp = false
    dat.forEach(element => {
      if(element.isActive){
        temp = true
        return temp
      }
    });
    return temp
  }
  getProductSettingFromOffice(office, productCode, settingName = null){
    if(!office || !office.products || !productCode || !Array.isArray(office.products)){
      return null;
    }
    let setting = null;
    let product = office.products.find(item => item.productCode == productCode);
    if(settingName){
      if(product && product.settings && Array.isArray(product.settings)){
        setting = product.settings.find(item => item.name == settingName)
      }
    }else{
      setting = product.settings
    }
    return setting;
  }
  componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }
  
  rgbToHex(rgbColor) {
    console.log('rgbColor------',rgbColor)
    rgbColor = rgbColor.slice(4,rgbColor.length-1).split(',')
    return "#" + this.componentToHex(Number(rgbColor[0])) + this.componentToHex(Number(rgbColor[1])) + this.componentToHex(Number(rgbColor[2]));
  }

  checkIfAddonExistsInAOffice(office, addOnCode){
    if(!office){
      return false
    }
    if(!addOnCode){
      return false;
    }

    if(office.products && Array.isArray(office.products)){
      for(let product of office.products){
        if(product.isActive && product.addOns && Array.isArray(product.addOns)){
          let addOnExist = product.addOns.find(item => item.isActive && item.code == addOnCode);
          if(addOnExist){
            return addOnExist;
          }
        }
      }
    }
    return false

  }
  dynamicFieldsValidation(customInformation){
    let validationDetails = {
      validationFailed : false,
      errorMsg : null
    }
    for(let i=0;i<customInformation.length;i++){
      if(customInformation[i].inputType != 'document' && customInformation[i].inputType != 'number'  && customInformation[i].inputType != 'email' && customInformation[i].isActive && customInformation[i].mandatory && (!customInformation[i].value || customInformation[i].value == '')){
        validationDetails.errorMsg = "Please enter valid " + customInformation[i].inputTitle.toLowerCase()
        validationDetails.validationFailed = true
        break
      }else if(customInformation[i].inputType == 'number' && customInformation[i].isActive && customInformation[i].mandatory && customInformation[i].value?.length<customInformation[i]?.minLength){
        validationDetails.errorMsg  = "Please enter valid " + customInformation[i].inputTitle.toLowerCase()
        validationDetails.validationFailed  = true
        break
      }else if(customInformation[i].inputType == 'email' && customInformation[i].isActive && customInformation[i].mandatory && !this.validateEmail(customInformation[i].value)){
        validationDetails.errorMsg = "Please enter valid " + customInformation[i].inputTitle.toLowerCase()
        validationDetails.validationFailed = true
        break
      }else if(customInformation[i].inputType == 'document' && customInformation[i].documentType == 'image' && customInformation[i].isActive && customInformation[i].mandatory && (!customInformation[i].value || (Array.isArray(customInformation[i].value) && customInformation[i].value.length == 0))){
        validationDetails.errorMsg = "Please upload " + customInformation[i].inputTitle.toLowerCase()
        validationDetails.validationFailed = true
        break
      }
    }
    return validationDetails
  }
  addFieldsValidation(customInformation){
    let validationDetails = {
      validationFailed : false,
      errorMsg : null
    }
    console.log('customInformation-----',customInformation)
    let allFieldsDeactivated = true;
    for(let i=0;i<customInformation.length;i++){

      if(customInformation[i].isActive){
        allFieldsDeactivated = false
      }
      if(!customInformation[i].inputType){
          validationDetails.errorMsg = "Please select field type"
          validationDetails.validationFailed = true
          break
      }else if(!customInformation[i].inputTitle){
        validationDetails.errorMsg = "Please enter field title"
        validationDetails.validationFailed = true
        break
      }else if(!customInformation[i].placeholder){
        validationDetails.errorMsg = "Please enter field placeholder"
        validationDetails.validationFailed = true
        break
      }else if(customInformation[i].inputType !='declaration' && customInformation[i].inputTitle.length>45){
        validationDetails.errorMsg = "Input title should be less than 45 characters"
        validationDetails.validationFailed = true
        break
      }
      else if(customInformation[i].inputType !='declaration' && customInformation[i].placeholder.length>45){
        validationDetails.errorMsg = "Input placeholder should be less than 45 characters"
        validationDetails.validationFailed = true
        break
      }
      if(customInformation[i].inputType == 'dropdown' && (!customInformation[i].dropdownList || customInformation[i].dropdownList.length<=1)){
        validationDetails.errorMsg = "Add atleast two options for dropdowns"
        validationDetails.validationFailed = true
        break
      }
    }
    if(allFieldsDeactivated){
      validationDetails.errorMsg = "All fields should not be deactivated."
        validationDetails.validationFailed = true
    }
    return validationDetails
  }
  submitField(customFields,existingSettings){
    console.log('customFields===',customFields)
    for(let i=0;i<customFields.length;i++){
      console.log(customFields[i])
      if(!customFields[i].inputType || customFields[i].inputType == ''){
        alert("Please select field Type")
        return
      }else if(!customFields[i].inputTitle || customFields[i].inputTitle == ''){
        alert("Please enter input title")
        return
      }
      if(!customFields[i].referenceName){
        customFields[i].referenceName = this.camelize(customFields[i].inputTitle) 
      }
      let referenceNameExist = existingSettings.findIndex((ele)=> ele.referenceName == customFields[i].referenceName )
      // if(referenceNameExist==-1){
        existingSettings.push(customFields[i])
      // }else{
        // existingSettings[referenceNameExist] = customFields[i]
      // }
    }
    return existingSettings
  }
  camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
  }
  getReadableDate(date,timezone= null, format='DD/MM/YYYY'){
    // let timezone = location?.timezone?.name

    if(!timezone){
      timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    }
    return  moment(date).tz(timezone).format(format);

    // let name = moment('2023-07-26T10:25:14.479Z').tz("Europe/Athens").format("h:mm a");
    // return name;
    // if(!locationInfo){
    //   timezone:'Asia/Culcutta'
    // }
  }
  getViewImagesUrlFromFeedbackComplaint(field, userName){
    console.log('field===',field)
    if(!field || !field.value || field.value.length<=0){
      return 
    }
    // field.value = ['https://qdesq.imagekit.io/image/upload/v1693311394/Qudify/flpjlhodl0b9tfvdebf3.png','https://qdesq.imagekit.io/image/upload/v1693311394/Qudify/flpjlhodl0b9tfvdebf3.png','https://qdesq.imagekit.io/image/upload/v1693311394/Qudify/flpjlhodl0b9tfvdebf3.png']
    // field.title ="Hello"
    // let domain = 'https://test.qudify.co/'
    // let images = field.value.map(item => item.split('https://ik.imagekit.io/')[1]).join(',')
    let images = field.value.join(',')
    let url = '/view-images/images?userName='+userName+'&title='+field.title+'&images='+images;
    console.log('url===',url)
    return url;
  }

  rotate(srcBase64, degrees, callback) {
    const canvas = document.createElement('canvas');
    const ctx    = canvas.getContext('2d');
    const image  = new Image();
  
    image.onload = function () {
      canvas.width  = degrees % 180 === 0 ? image.width : image.height;
      canvas.height = degrees % 180 === 0 ? image.height : image.width;
  
      ctx.translate(canvas.width / 2, canvas.height / 2);
      ctx.rotate(degrees * Math.PI / 180);
      ctx.drawImage(image, image.width / -2, image.height / -2);
  
      callback(canvas.toDataURL());
    };
  
    image.src = srcBase64;
  }
  isLinkedToHrOne(company){
    return company?.thirdPartyIntegrationDetails?.integrations?.find(item => item.thirdPartyName == 'hrOne')
  }

  getCookie(cname) { 
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  getGclid(){
    var gclid = null;
    var queryUrl = window.location.search;
    var urlSearchParams = new URLSearchParams(queryUrl);
    gclid = urlSearchParams.get('gclid')
    if(gclid && typeof(gclid)=='string' && gclid.length>5){
      return gclid;
    }else{
      var coockies:any = this.getCookie('_gcl_aw');
      if(coockies && coockies.length>5){
        coockies = coockies.split('.');
        gclid = coockies[coockies.length-1];
      }
    }
  return gclid;
  }

  downloadExcelFromApi(type,params,fileName,callback,fullUrl=null){
    let url = `${type}/get/excel`
    if(fullUrl){
      url = fullUrl;
    }
    this.api.downloadDocument(url,params).subscribe((res:any)=>{
      if(!res.error){
        let url = res.body
        var link = document.createElement('a');
        link.href = window.webkitURL.createObjectURL(url);
        link.download =fileName;
        document.body.appendChild(link);
        callback(res)
        link.click();
        }
        else{
          callback({error:true,message:res.message})
        }
    },
    (err)=>{
      callback({error:true,message:err.error.message || err.message})
    }
    )
  }

  getDefaultVisitorCheckinDeclaration(){
    return {
      isActive: true,
      displayText:"<p>I confirm that I am not suffering from any covid’19</p>",
      document: {
        url: null,
        name: null
      }
    }
  }
  unsubscribeSubscriptions(allProps){
    for (const prop in allProps) {
      const property = allProps[prop];
      // Check if the property is a subscription
      if (property && typeof property.unsubscribe === 'function') {
         // Call the unsubscribe function to unsubscribe from the subscription
        property.unsubscribe();
      }
    }
  }

timeConverter(time) {
    console.log('time',time);
    if (time) {
        time = time.toString();
        if(time.length == 2){
          time = '0'+time;
          var hours = time[0];
          var min =time[1]+time[2];
        }
        else if (time.length == 3) {
            time = time;
            var hours = time[0];
            var min =time[1]+time[2];
        } else if (time.length == 4) {
            var hours = time[0] + time[1];
            var min = time[2] + time[3];
        }
        if (hours < 12) {
            if(hours=='0' && min=='00' ){
                return '12:00 AM';
            }else{
                return hours + ':'+ min+' AM';
            }

        } else {
            if(hours=='24' && min=='00'){
                return '12:00 AM';
            }
            hours = hours - 12;
            // hours = (hours.length < 10) ? '0' + hours : '0' + hours;
            return hours + ':'+ min+' PM';
        }
    }
}
  getOfficeTime(office){
    let days = ['sun','mon','tue','wed','thu','fri','sat'];

    let currentDay = days[new Date().getDay()];

    if(!office.officeHours[currentDay].from || !office.officeHours[currentDay].to){
      return 'Closed';
    }else {
      return this.timeConverter(office.officeHours[currentDay].from) + " - "+ this.timeConverter(office.officeHours[currentDay].to)
    }
  }
  getOfficeCloseDays(office) {
      let closeDays = [];
      if(!office.officeHours?.mon?.from || !office.officeHours?.mon?.to){
        closeDays.push('Monday')
      }
      if(!office.officeHours?.tue?.from || !office.officeHours?.tue?.to){
        closeDays.push('Tuesday')
      }
      if(!office.officeHours?.wed?.from || !office.officeHours?.wed?.to){
        closeDays.push('Wednesday')
      }
      if(!office.officeHours?.thu?.from || !office.officeHours?.thu?.to){
        closeDays.push('Thursday')
      }
      if(!office.officeHours?.fri?.from || !office.officeHours?.fri?.to){
        closeDays.push('Friday')
      }
      if(!office.officeHours?.sat?.from || !office.officeHours?.sat?.to){
        closeDays.push('Saturday')
      }
      if(!office.officeHours?.sun?.from || !office.officeHours?.sun?.to){
        closeDays.push('Sunday')
      }
      
      return closeDays;
  }

  maskEmail(email) {
    if(!email){
      return
    }
    let maskedEmail = ''
    for(let i=0;i<email.split('@')[0].length;i++){
      maskedEmail = maskedEmail + (i%2 == 1 ? '*' :email.split('@')[0][i])
    }
    maskedEmail = maskedEmail +'@' + email.split('@')[1]

    return maskedEmail;
  }
  usersAvatarImg(name){
    return 'https://ui-avatars.com/api/?name=' +
    name +'&background='+'random' +'&length=2' +'&bold=' +true +'&rounded=' +true
  }

  validateVisitHostDetails(office, visitsData){

    let notificationToHostSetting = 'whatsapp'
    let notificationToHostSettingExist = this.getProductSettingFromOffice(office,2,'visitor-meeting-appointment-approval-notification')
    if(notificationToHostSettingExist){
      notificationToHostSetting = notificationToHostSettingExist.value
    }

    if(!notificationToHostSetting || notificationToHostSetting == 'whatsapp'){

      if(!visitsData.details['contactNumber']){
        return 'Phone number not found in host details'
        return
      }else if(visitsData.details['contactNumber'] && !this.validation.validatePhoneNumber(visitsData.details['contactNumber'], visitsData.details['maxValidContactNumberLength'], visitsData.details['minValidContactNumberLength'])){
        return 'Phone number is not valid in host details'
      }

    }
    else if(notificationToHostSetting == 'email'){
      if(!visitsData.details['hostEmail']){
        return 'Email id not found in host details'
      }else if(visitsData.details['hostEmail'] && !this.validateEmail(visitsData.details['hostEmail'])){
        return 'Email id is not valid in host details'
      }
    }
    else if(notificationToHostSetting == 'all'){
      if((visitsData.details['hostEmail'] && !this.validateEmail(visitsData.details['hostEmail'])) || (visitsData.details['contactNumber'] && !this.validation.validatePhoneNumber(visitsData.details['contactNumber'], visitsData.details['maxValidContactNumberLength'], visitsData.details['minValidContactNumberLength']))){
        
      }else if(visitsData.details['hostEmail'] && !this.validateEmail(visitsData.details['hostEmail'])){
        return 'Email id or phone number not found in host details'
      }
    }

    return null;

  }
    createCookie(name,value,days) {
      if (days) {
          let date = new Date();
          date.setTime(date.getTime()+(days* 24 * 60 * 60 * 1000));
          var expires = "; expires="+date.toUTCString();
      } else {
          var expires = "";
      }
      document.cookie = name+"="+value+expires+"; path=/";
    }
    getCompaignParameterCookie(name) {
      var value = `; ${document.cookie}`;
      var parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    }

    detectDeviceType(){
      const userAgent = navigator.userAgent.toLowerCase();

      // returns apple, window, android 

      if (userAgent.indexOf("mac") !== -1 || /iPad|iPhone|iPod/.test(navigator.platform)) {
         return 'apple'
      } else if (userAgent.indexOf("win") !== -1) {
          return 'window'
      } else {
         return 'android'
      }
    }

    getCategoryNameFromPassConfiguration( categoryCode, company, preInvite ) {
      try {
    
        if ( categoryCode ) {
          let passConfiguration = []
          if ( preInvite ) passConfiguration = company?.passConfiguration?.isActive && company?.passConfiguration?.preInvitePass ? company.passConfiguration.preInvitePass : []
          else passConfiguration = company?.passConfiguration?.isActive && company?.passConfiguration?.visitorPass ? company.passConfiguration.visitorPass : []
          let item = passConfiguration.find(item => item.code == categoryCode )
          let categoryName = item ? item.displayName : this.categoryType(categoryCode)
          return categoryName
        }
        return null
        
      } catch (error) {

      } 
    }
    categoryType(type) {
      // try catch block for errors and exceptions handling start here
      switch (type) {
        case 1:
          return 'i work here';
    
        case 2:
          return 'meeting appointment';
    
        case 3:
          return 'delivery';
    
        case 4:
          return 'service';
    
        case 5:
          return 'other';
    
        case 6:
          return 'company pass';
    
        case 7:
          return 'vip';
    
        case 8:
          return 'access pass';
    
        case 9:
          return 'event pass'; // earlier member pass
    
        default:
          return 'other';
      }
    }
    isCompanyLinkedToThirdParty(company, type){
      return company?.thirdPartyIntegrationDetails?.integrations?.find(item => item.thirdPartyName == type)
    }

    getCategoryCodeFromVisit(visit){
      if(!visit || !visit.category){
        return;
      }

      return visit.category.similarToCode || visit.category.code

    }

    isValidMongoId(id) {
      // Check if the input is a string and has exactly 24 characters
      if (typeof id !== 'string' || id.length !== 24) {
        return false;
      }
    
      // Check if the string contains only hexadecimal characters
      const hexPattern = /^[a-fA-F0-9]{24}$/;
      return hexPattern.test(id);
    }
    encodeJWTToken(token){
          var newToken = token.substring(0,12)+'xss'+token.substring(12,token.length);
          newToken = window.btoa(newToken);
          return newToken;
      
    }

    decodeJWTToken(token){
      if(token){
          var decryption1 = window.atob(token);
          var decryption2 = decryption1.substring(0,12)+decryption1.substring(15,decryption1.length);
          return decryption2;
      }
    
    }

    haveCommonValues(arr1, arr2) {
      if(arr1 && arr2){
        return arr1.some(item => arr2.includes(item));
      }
     
    }
    isCurrentTimeWithinOfficeHours(currentDateTime, officeHours){
      try {
    
        const currentDate = new Date(currentDateTime);
        const currentDay = currentDate.toLocaleDateString('en-US', { weekday: 'short' }).toLowerCase();
        const currentTime = currentDate.getHours() * 100 + currentDate.getMinutes();
    
        if (currentDay in officeHours) {
          const { from, to } = officeHours[currentDay];
    
          // Check if the current time is within the specified range
          if (currentTime >= from && currentTime <= to) {
            return true;
          }
        }
    
        return false
    
      } catch (error) {
       
      }
    }
    isLandscape() {
      return window.innerWidth > window.innerHeight;
    }

  }